import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  forwardRef,
} from 'react'
import L from 'leaflet'
import 'leaflet-iiif'
import 'leaflet/dist/leaflet.css'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
import Video from '../Video/Video'
const config = require('../../config.js')

import { convertImageUrlToIiif } from '../../helper-functions/string-functions'
import {
  audioTypes,
  imageTypes,
  videoTypes,
} from '../../helper-functions/metadata-functions'
import { IconLabel } from '../IconLabel/IconLabel'
import { Button } from '../Button/Button'
import PDFViewer from '../PDFViewer/PDFViewer'

export const ObjectPreview = forwardRef(
  ({ interact, object, setInteractHandler, ...props }, ref) => {
    const [type, setType] = useState(returnObjectTypeFromObject(object))
    const [Boxes, setBoxes] = useState([])
    const [mapInstance, setMapInstance] = useState(null)
    const [tileLayerInstance, setTileLayerInstance] = useState(null)
    const [map, setMap] = useState(null)
    const mapContainerRef = useRef(null)
    const mapInitializedRef = useRef(false)

    useEffect(() => {
      if (object) setType(returnObjectTypeFromObject(object))
    }, [object])

    const cleanupMap = useCallback(() => {
      try {
        if (tileLayerInstance && mapInstance) {
          // First remove the tile layer from the map specifically
          mapInstance.removeLayer(tileLayerInstance)
          tileLayerInstance.off()
          setTileLayerInstance(null)
        }

        if (mapInstance && mapInstance._container) {
          mapInstance.off()
          mapInstance.remove()
          setMapInstance(null)
        }
      } catch (err) {
        console.warn('Error during map cleanup:', err)
      }
      mapInitializedRef.current = false
    }, [mapInstance, tileLayerInstance])

    const initMap = () => {
      if (
        !mapContainerRef.current ||
        !object?.objectUrl ||
        mapInitializedRef.current
      ) {
        return
      }

      try {
        const newMap = L.map(mapContainerRef.current, {
          attributionControl: false,
          center: [0, 0],
          crs: L.CRS.Simple,
          zoom: 0,
          minZoom: 0.4,
        })

        const newTileLayer = L.tileLayer.iiif(
          convertImageUrlToIiif(object.objectUrl) + '/info.json',
          {
            tileOptions: {
              transparent: true,
            },
          }
        )

        newTileLayer.addTo(newMap)

        newMap.on('zoomstart', () => newTileLayer.setOpacity(0))
        newMap.on('zoomend', () => newTileLayer.setOpacity(1))

        setMapInstance(newMap)
        setTileLayerInstance(newTileLayer)
        mapInitializedRef.current = true
      } catch (error) {
        console.error('Error initializing map:', error)
      }
    }

    useEffect(() => {
      if (type === 'Image' && object?.objectUrl && !mapInitializedRef.current) {
        initMap()
      }
      return () => {
        cleanupMap()
      }
    }, [type, object?.objectUrl])

    return object ? (
      <div
        className={[
          'object-view__preview-object',
          //
          'bg-base-darkest',
          'display-flex',
          'flex-align-center',
          'flex-column',
          'flex-justify-center',
          'grid-col',
          'minh-0',
          // 'padding-1',
          'width-full',
        ].join(' ')}
      >
        <div
          className={[
            'preview-object',
            //
            'bg-base-darkest',
            'display-flex',
            'flex-align-center',
            'flex-justify-center',
            'height-full',
            'minh-0',
            'position-relative',
            'width-full',
            interact ? 'active' : 'inactive',
          ].join(' ')}
          id="digital-object-preview"
          role="tabpanel"
        >
          {type == 'Video' && (
            <Video
              file={object?.objectUrl}
              format={returnFileFormat(object?.objectType)}
              {...props}
            />
          )}
          {type == 'PDF' && <PDFViewer file={object?.objectUrl} />}
          {(type == 'Image' || type == 'PDF') && (
            <>
              {!interact && (
                <div
                  className={[
                    // 'interact-overlay',
                    'anim-opacity-0',
                    'anim-duration-250ms',
                    'anim-delay-0',
                    'bg-base-darkest-opacity-0',
                    'bg-transparent',
                    'clickable',
                    'display-flex',
                    'flex-align-center',
                    'flex-justify-center',
                    'flex-row',
                    'height-full',
                    'position-absolute',
                    'text-bold',
                    'text-transparent',
                    'width-full',
                    'z-500',
                  ].join(' ')}
                  data-testid="nac-digital-objects__interact-overlay"
                  onClick={() => setInteractHandler(true)}
                  // ref={ref}
                >
                  <Button
                    // iconAnimation="rotate-cw"
                    iconName="gear"
                    onClick={() => setInteractHandler(true)}
                    outlineColor="transparent"
                    theme="transparent"
                  >
                    Click to interact
                  </Button>
                </div>
              )}
              {type == 'Image' && (
                <div
                  className={[
                    'bg-transparent',
                    'height-full',
                    'overflow-hidden',
                    'width-full',
                  ].join(' ')}
                  key={object?.objectUrl}
                  ref={mapContainerRef}
                  {...props}
                ></div>
              )}
            </>
          )}
          {/**TO DO: Create an Audio component */}
          {type == 'Audio File' && (
            <audio controls key={object?.objectUrl}>
              <source
                src={object?.objectUrl}
                type={`audio/${returnFileFormat(object?.objectType)}`}
              />
              Your browser does not support the audio element.
            </audio>
          )}
        </div>
      </div>
    ) : (
      ''
    )
  }
)

ObjectPreview.defaultProps = {
  object: {},
}

ObjectPreview.propTypes = {
  //  TODO: Temp Comment Out for now Coordinates Highlights
  // search: PropTypes.string,
  setInteractHandler: PropTypes.func,
  hover: PropTypes.bool,
  interact: PropTypes.bool,
  object: PropTypes.object?.isRequired,
}

ObjectPreview.displayName = 'ObjectPreview'

export const returnFileFormat = (string) => {
  if (!string) return
  let format = ''
  const regExp = /\(([^)]+)\)/
  let match = regExp.exec(string)
  format = match?.[1].toLowerCase()
  if (!format)
    format = string?.split(/[#?]/)[0]?.split('.')?.pop()?.trim().toLowerCase()
  return format
}

export const returnObjectTypeFromObject = (object) => {
  if (!object || (!object.objectType && !object.objectFilename)) return 'Object'
  else if (
    returnFileFormat(object?.objectType || object?.objectFilename) == 'pdf'
  )
    return 'PDF'
  else if (
    imageTypes.some(
      (element) =>
        object?.objectType?.includes(element) ||
        object?.objectFilename?.includes(element)
    )
  )
    return 'Image'
  else if (
    videoTypes.some(
      (element) =>
        object?.objectType?.includes(element) ||
        object?.objectFilename?.includes(element)
    )
  )
    return 'Video'
  else if (
    audioTypes.some(
      (element) =>
        object?.objectType?.includes(element) ||
        object?.objectFilename?.includes(element)
    )
  )
    return 'Audio File'
}
