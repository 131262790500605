/**
 * Schema for building and validating the online availability dropdown input
 * TO DO:
 */
export const onlineAvailabilitySchema = {
  allow: ['array', 'string'],
  description:
    'Manually sets the online availability header for the given record. If set to none, the system will determine the availability to be either Not Digitized or Partially Digitized based on the number of digital objects within the child records.',
  id: 'onlineAvailability',
  label: 'Online Availability',
  multiSelect: true,
  options: [
    {
      description: 'This record is fully available online.',
      id: 'onlineAvailability-fullyDigitized',
      label: 'Fully Available Online',
      order: 1,
      value: 'fullyDigitized',
    },
    {
      description:
        'This record contains unrestricted records which are fully available online.',
      id: 'onlineAvailability-unrestrictedOnly',
      label: 'Unrestricted Only',
      order: 2,
      value: 'unrestrictedOnly',
    },
    {
      description:
        'These records are digitized and unavailable online due to possible copyright or other use restrictions. ' +
        'They may be available to access on site at the National Archives. ' +
        'Please contact the following location prior to your visit to confirm current availability.',
      id: 'onlineAvailability-notAvailableUse',
      label: 'Not Available Online (Use)',
      order: 3,
      value: 'notAvailableUse',
    },
    {
      description:
        'This record contains records, none of which are available online.',
      id: 'onlineAvailability-none',
      label: 'Default Banner',
      order: 4,
      value: '',
    },
  ],
  searchable: false,
  sr: 'Online availability multi-select dropdown field',
  type: 'select',
}

export const onlineAvailabilityAlertSchema = [
  {
    description: 'This record is fully available online.',
    id: 'fullyDigitized',
    label: 'Fully Available Online',
    title: 'Fully Available Online',
    type: 'success',
  },
  {
    description:
      'This record contains records, some of which are available online.',
    id: 'partiallyDigitized',
    label: 'Partially Available Online',
    title: 'Partially Available Online',
    type: 'warning',
  },
  {
    description:
      'This record contains unrestricted records which are fully available online.',
    id: 'unrestrictedOnly',
    label: 'Partially Available Online',
    secondaryLabel: 'Unrestricted Only',
    title: 'Partially Available Online: Unrestricted Only',
    type: 'warning',
  },
  {
    description: '',
    id: 'notDigitized',
    label: 'Not Yet Available Online',
    title: 'Not Yet Available Online',
    type: 'warning',
  },
  {
    description:
      'These records are digitized and unavailable online due to possible copyright or other use restrictions. ' +
      'They may be available to access on site at the National Archives. ' +
      'Please contact the following location prior to your visit to confirm current availability.',
    id: 'notAvailableUse',
    label: 'Not Available Online (Use Restrictions)',
    title:
      'Not Available Online Due to Possible Copyright or Other Use Restrictions',
    type: 'high-warning',
  },
]

export const onlineAvailabilityPropTypes =
  onlineAvailabilityAlertSchema?.reduce((a, b) => [...a, b.id], [])
