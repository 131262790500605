export const onlineAvailabilitySchema = [
  {
    description:
      'This record has been digitized and is now fully available online.',
    id: 'fullyDigitized',
    label: 'Fully Available Online',
    title: 'Fully Available Online',
    type: 'success',
  },
  {
    description:
      'This record contains records, some of which are available online.',
    id: 'partiallyDigitized',
    label: 'Partially Available Online',
    title: 'Partially Available Online',
    type: 'warning',
  },
  {
    description:
      'The unrestricted records in this record are fully available online.',
    id: 'unrestrictedOnly',
    label: 'Partially Available Online',
    secondaryLabel: 'Unrestricted Only',
    title: 'Partially Available Online: Unrestricted Only',
    type: 'warning',
  },
  {
    description: '',
    id: 'notDigitized',
    label: 'Not Yet Available Online',
    title: 'Not Yet Available Online',
    type: 'warning',
  },
  {
    description:
      'These records are digitized and unavailable online due to possible copyright or other use restrictions. ' +
      'They may be available to access on site at the National Archives. ' +
      'Please contact the following location prior to your visit to confirm current availability.',
    id: 'notAvailableUse',
    secondaryLabel: 'Not Available Online (USE)',
    label: 'Not Available Online (Use Restrictions)',
    title:
      'Not Available Online Due to Possible Copyright or Other Use Restrictions',
    type: 'high-warning',
  },
]
