import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import '../../../styles/index.scss'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { descriptionResults } from '../../settings/RecordPropTypes'
//Context
// import SearchContext from '../../../contexts/SearchContext'
//Components
import Ancestors from './result-ancestors'
import ControlNumbers from './result-ids'
import Creators from './result-creators'
import Description from './result-description'
import Level from './result-level'
import Thumbnail from './result-thumbnail'
import Title from './result-title'
//Helpers
import { returnTotalResultIndex } from '../../../helper-functions/param-functions'
import OrganizationNames from './result-organization-names'
import { DEFAULT_LIMIT, DEFAULT_PAGE } from '../../settings/globals'
import { useParams } from 'react-router-dom'
import CheckboxField from '../../form/CheckboxField'
import ExportContext from '../../../contexts/ExportContext'

export const Results = ({ main, results, total, ...props }) => {
  const { naId } = useParams()
  const { pathname, search } = useLocation()
  const { limit = DEFAULT_LIMIT, page = DEFAULT_PAGE } =
    queryString.parse(search)

  let newSearch = search
  if (naId && !search) {
    newSearch = `?ancestorNaId=${naId}&sort=naId:asc`
  }

  if (naId && newSearch && !newSearch.includes('sort')) {
    newSearch += `&sort=naId:asc`
  }

  const {
    allChecked,
    naIdsToIncludeInExport,
    naIdsToExcludeFromExport,
    setCheckedResultHandler,
  } = useContext(ExportContext) || {}

  return (
    results && (
      <ul className="usa-card-group" {...props}>
        {results.map((result, index) => (
          <li className="usa-card width-full margin-bottom-2" key={result.naId}>
            <div
              className={[
                'search-result',
                (allChecked &&
                  naIdsToExcludeFromExport.filter((r) => r === result.naId)
                    .length === 0) ||
                naIdsToIncludeInExport.filter((r) => r === result.naId).length >
                  0
                  ? 'selected bg-primary-lightest'
                  : 'bg-white',
                'display-flex',
                'flex-gap',
                'flex-row',
                'grid-row',
                'padding-1',
                'radius-md',
                'shadow-xs',
              ].join(' ')}
            >
              <div
                className="no-print"
                style={{
                  marginTop: '-4px',
                  position: 'absolute',
                  zIndex: 1000,
                }}
              >
                {pathname === '/search' && (
                  <CheckboxField
                    className="result_checkbox"
                    checked={
                      (allChecked &&
                        naIdsToExcludeFromExport.filter(
                          (r) => r === result.naId
                        ).length === 0) ||
                      naIdsToIncludeInExport.filter((r) => r === result.naId)
                        .length > 0
                    }
                    data-testid={`nac-result_${result.naId}`}
                    id={`nac-result_${result.naId}`}
                    label={
                      <span className="usa-sr-only">
                        Select result {index + 1}
                      </span>
                    }
                    onChange={() => setCheckedResultHandler(result.naId)}
                    onKeyPress={() => setCheckedResultHandler(result.naId)}
                    value="display-highlights"
                  />
                )}
              </div>
              <Thumbnail record={result} size="xl" />
              <div
                className={[
                  'display-flex',
                  'flex-column',
                  'flex-gap-sm',
                  'grid-col',
                ].join(' ')}
              >
                <span className="display-flex flex-column">
                  <Level record={result} />

                  <Link
                    className="result-link"
                    data-testid={`nac-results--title-${result.naId}`}
                    data-index={returnTotalResultIndex(index, limit, page)}
                    to={{
                      pathname: `/id/${result.naId}`,
                    }}
                    state={{
                      // data: result,
                      from: { pathname: pathname, search: newSearch },
                      results:
                        total > 1
                          ? {
                              index: returnTotalResultIndex(index, limit, page),
                              total: total,
                            }
                          : null,
                      prevPath: location.pathname,
                    }}
                  >
                    <Title record={result} />
                  </Link>
                </span>
                <Description record={result} />
                <span>
                  <ControlNumbers record={result} />
                  <Creators record={result} />
                  <Ancestors record={result} recordGroup={main} />
                </span>
                {result.authorityType === 'organization' &&
                  result.organizationNames.length > 1 && (
                    <OrganizationNames
                      record={result}
                      state={{
                        // data: result,
                        from: { pathname: pathname, search: newSearch },
                        results:
                          total > 1
                            ? {
                                index: returnTotalResultIndex(
                                  index,
                                  limit,
                                  page
                                ),
                                total: total,
                              }
                            : null,
                      }}
                    />
                  )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    )
  )
}

Results.defaultProps = {}

Results.propTypes = {
  limit: PropTypes.number,
  /**
   * When true, the assumption is that the component is appearing on the main results page
   */
  main: PropTypes.bool,
  /**
   * The current paginated results page
   */
  page: PropTypes.number,
  /**
   * An array of one or more data objects for individual records
   */
  results: descriptionResults.isRequired,
  /**
   * The total number of results returned
   */
  total: PropTypes.number,
}
