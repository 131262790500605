import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import queryString from 'query-string'
import { SERVER_URL } from '../components/settings/globals'
//Hooks
import useHttp from './use-http'
//Helpers
import { returnIntegerFromString } from '../helper-functions/number-functions'
var uuid = require('uuid')

const useLogin = () => {
  const [loggedInUser, setLoggedInUser] = useState(
    JSON.parse(localStorage.getItem('loggedInUser'))
  )
  const [loggedInUserAvatar, setLoggedInUserAvatar] = useState(null)
  const [loadingLoggedInUser, setLoadingLoggedInUser] = useState(false)

  // extract pathname from location
  const navigate = useNavigate()
  const { pathname, search, state } = useLocation()
  const [loginState, setLoginState] = useState(null)
  const [encryptedUuid, setEncryptedUuid] = useState('')
  const queryParams = queryString.parse(search)
  const [searchParams, setSearchParams] = useSearchParams()
  const login = () => {
    setLoginState(uuid.v4())
  }

  const handleUsernameChange = (newusername) => {
    const newaccount = loggedInUser
    newaccount.userName = newusername
    setLoggedInUser(newaccount)
    localStorage.setItem('loggedInUser', JSON.stringify(newaccount))
  }

  useEffect(() => {
    if (loginState) {
      localStorage.setItem('state', loginState)
      localStorage.setItem('currentPageState', JSON.stringify(state))
      window.location.href = `${SERVER_URL}/auth/login-gov/login/ial-1?state=${loginState}&redirect=${encodeURIComponent(
        pathname
      )}&queryParams=${encodeURIComponent(search)}`
    }
  }, [loginState])

  useEffect(() => {
    if (localStorage.getItem('state') == queryParams.state) {
      // Clear the login in state as we no longer need it.
      setLoginState(null)
      setEncryptedUuid(queryParams.access)
      if (searchParams.get('access')) {
        searchParams.delete('access')
        setSearchParams(searchParams)
      }
      if (searchParams.get('state')) {
        searchParams.delete('state')
        setSearchParams(searchParams)
      }
      const currentPageState = localStorage.getItem('currentPageState')
      if (currentPageState) {
        localStorage.removeItem('currentPageState')
        setSearchParams(searchParams, {
          replace: true,
          state: JSON.parse(currentPageState),
        })
      }
    }
  }, [search])

  // States
  // If no option exists in session storage,
  // set the initial highlighter state to be the default
  const [displayAdminWorkbench, setDisplayAdminWorkbench] = useState(true)

  // Functions
  const setDisplayAdminWorkbenchHandler = () => {
    setDisplayAdminWorkbench((prev) => !prev)
  }

  useEffect(() => {
    if (sessionStorage.getItem('displayAdminWorkbench'))
      setDisplayAdminWorkbench(
        sessionStorage.getItem('displayAdminWorkbench') == 'true'
      )
  }, [])

  useEffect(() => {
    sessionStorage.setItem('displayAdminWorkbench', displayAdminWorkbench)
  }, [displayAdminWorkbench])

  useEffect(() => {
    if (encryptedUuid) {
      requestUserDataHandler()
    }
  }, [encryptedUuid])

  const logout = () => {
    let state = localStorage.getItem('state') || ''
    let uuid = loggedInUser.encryptedUuid || ''

    setLoggedInUser(null)
    localStorage.removeItem('loggedInUser')

    if (state && uuid) {
      window.location.href = `${SERVER_URL}/auth/logout/${uuid}/${state}?postLogoutPath=${encodeURIComponent(
        pathname
      )}&postLogoutQueryParams=${encodeURIComponent(search)}`
    }
  }

  const handleUserDataResponse = (results) => {
    if (!results) {
      //TO DO: handle with error
      return false
    }
    localStorage.setItem('loggedInUser', JSON.stringify(results))
    setLoggedInUser(results)
    updateUserData(results)
    setTimeout(() => {
      setLoadingLoggedInUser(false)
    }, 500)
  }

  const updateUserData = (data) => {
    if (data.userName) {
      setLoggedInUserAvatar(
        `/images/avatars/${data.userName
          .charAt(0)
          .toLowerCase()}-${returnIntegerFromString(data.userName || '', [
          1,
        ])}.png`
      )
    }
  }

  const {
    error: getUsersError,
    isLoading: getUsersLoading,
    sendRequest: requestUserDataHandler,
  } = useHttp(
    {
      url: `${SERVER_URL}/users/${encryptedUuid}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    handleUserDataResponse
  )

  const loggedInUserIsAdmin =
    (loggedInUser && loggedInUser.role === 'administrator') || false
  const loggedInUserIsModerator =
    (loggedInUser &&
      (loggedInUser.role === 'moderator' ||
        loggedInUser.role === 'administrator')) ||
    false

  const route = pathname?.split('/')?.[1]
  const userName = pathname?.split('/')?.[2]
  const isLoggedInUsersPage =
    (loggedInUser &&
      route === 'accounts' &&
      loggedInUser.userName?.toLowerCase() === userName.toLowerCase()) ||
    false

  const accessGrantedToPublicDetails = true
  const accessGrantedToAccountDetails =
    loggedInUserIsAdmin || loggedInUserIsModerator || isLoggedInUsersPage
  const accessGrantedToAdminAccountDetails =
    loggedInUserIsAdmin || loggedInUserIsModerator
  const accessGrantedToModeratorTools =
    loggedInUserIsModerator || loggedInUserIsAdmin //&& isLoggedInUsersPage
  const accessGrantedToAccountAdminTools = loggedInUserIsAdmin // && isLoggedInUsersPage

  const accountsAccessArray = {
    accessGrantedToPublicDetails: accessGrantedToPublicDetails,
    isLoggedInUsersPage: isLoggedInUsersPage,
    accessGrantedToAccountDetails: accessGrantedToAccountDetails,
    accessGrantedToAdminAccountDetails: accessGrantedToAdminAccountDetails,
    accessGrantedToModeratorTools: accessGrantedToModeratorTools,
    accessGrantedToAccountAdminTools: accessGrantedToAccountAdminTools,
  }

  return {
    accountsAccessArray,
    loggedInUser,
    loggedInUserIsAdmin,
    loggedInUserIsModerator,
    loggedInUserAvatar,
    loadingLoggedInUser,
    isLoggedInUsersPage,
    login,
    logout,
    handleUsernameChange,
    displayAdminWorkbench,
    setDisplayAdminWorkbenchHandler,
  }
}
export default useLogin
