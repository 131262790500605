import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import queryString from 'query-string'
//Components
import ExpandCollapseList from '../../utilities/ExpandCollapseList'
import FilterGroup from './FilterGroup'
import RadioButtonField from '../RadioButtonField'
import SelectedFilters from './SelectedFilters'
import { Icon } from '../../Icon/Icon'
//Helpers
import {
  convertSentenceToCamel,
  returnNumberWithOrdinalSuffix,
} from '../../../helper-functions/string-functions'
import {
  returnIconAndLabelFromString,
  returnValueAndLabelFromString,
} from '../../../helper-functions/metadata-functions'
import { congressArray } from '../../../schemas/input-schemas/congress-schema'
import { returnNumberWithCommas } from '../../../helper-functions/number-functions'
import BarChartCongressFilter from '../../BarChart/variants/BarChartCongressFilter'

const CatalogFilters = ({
  className,
  filters,
  handleUpdateValue,
  ...props
}) => {
  const { search } = useLocation()
  const query = queryString.parse(search)

  const [checked, setChecked] = useState(
    Object.keys(query).indexOf('availableOnline') > -1
      ? 'available-online'
      : 'all-records'
  )

  const handleSetChecked = (value) => {
    if (value !== checked) {
      setChecked(value)
      handleUpdateValue(
        'availableOnline',
        value == 'available-online',
        value !== 'available-online'
      )
    }
  }

  const handleFilterClick = (filter, option, multiSelect, quotes = true) => {
    let remove = false
    let selected = option
    if (quotes) {
      selected = '"' + option + '"'
    }
    if (filter === 'dates') {
      if (option.indexOf('-') > -1) {
        const currentOption = query
          ? `${query.startDate || ''}-${query.endDate || ''}`
          : ''
        if (currentOption === option) {
          handleUpdateValue('dates', '-', remove)
        } else {
          handleUpdateValue('dates', option, remove)
        }
      } else if (option.indexOf('After') > -1) {
        const afterDate = option.split('After ')[1]
        remove = (afterDate === query?.startDate || '') && !query?.endDate
        handleUpdateValue('startDate', afterDate, remove)
        handleUpdateValue('endDate', '', remove)
      } else if (option.indexOf('Before') > -1) {
        const beforeDate = option.split('Before ')[1]
        remove = (beforeDate === query?.endDate || '') && !query?.startDate
        handleUpdateValue('startDate', '', remove)
        handleUpdateValue('endDate', beforeDate, remove)
      }
    } else {
      if (multiSelect) {
        if (quotes) {
          selected = `"${option.replace(/,/g, '')}"`
        } else {
          selected = option.replace(/,/g, '')
        }
        if (query?.[filter]) {
          let currentOptions = query[filter].split(',')
          if (currentOptions.includes(selected)) {
            currentOptions = currentOptions.filter((opt) => opt !== selected)
          } else {
            currentOptions.push(selected)
          }
          selected = currentOptions.join(',')
        }
      } else {
        Object.entries(query).forEach(([key, value]) => {
          if ((key === filter && value === selected) || !selected) {
            remove = true
          }
        })
      }
      handleUpdateValue(filter, selected, remove)
    }
  }

  // Orders buckets so the items being used in the filter are at the top
  const reorderBucketOptions = (buckets, key) => {
    buckets.map((option, index) => {
      const hasValue = query?.[key]
        ?.split(',')
        .map((el) => el.trim())
        .includes(option?.value ? option?.value : option?.key)
      if (hasValue) {
        const newBuckets = buckets
        newBuckets.splice(index, 1)
        newBuckets.unshift(option)
        buckets = newBuckets
      }
    })
  }

  const order = [
    'dataSource',
    'typeOfMaterials',
    'levelOfDescription',
    'objectType',
    'recordGroupNumber',
    'collectionIdentifier',
    'referenceUnits',
    'dates',
  ]
  // _.sortBy(arr, function (obj) {
  //   return _.indexOf(order, obj.key)
  // })

  const returnFilters = () => {
    let jsx = []
    let index = 0

    const newFilters = []
    for (const [key, value] of Object.entries(filters)) {
      newFilters.push({ key: key, order: order.indexOf(key), value: value })
    }

    // for (const [key, value] of Object.entries(filters)) {
    newFilters
      .sort((a, b) => a.order - b.order)
      .map(({ key, value }) => {
        let label = ''
        let buckets = value.buckets
        let isOpen = true
        let multiSelect = false
        let wrapInQuotes = false

        // value?.buckets?.order = order?.indexOf(key)
        switch (key) {
          case 'levelOfDescription':
            label = 'Level of Description'
            multiSelect = true
            reorderBucketOptions(buckets, key)
            break
          case 'dataSource':
            label = 'Data Source'
            break
          case 'typeOfMaterials':
            label = 'Type of Materials'
            multiSelect = true
            reorderBucketOptions(buckets, key)
            break
          // case 'congressNumber':
          //   label = 'Congress Number'
          //   buckets = []
          //   for (const [k, v] of Object.entries(value.buckets)) {
          //     if (v.doc_count)
          //       buckets.push({
          //         key: returnNumberWithOrdinalSuffix(k),
          //         value: k,
          //         doc_count: v.doc_count,
          //       })
          //   }
          //   isOpen = false
          //   multiSelect = true
          //   reorderBucketOptions(buckets, key)
          //   break
          case 'collectionIdentifier':
            label = 'Part of Collections'
            buckets = value.filter_collections.collections.buckets
            for (const [k, v] of Object.entries(buckets)) {
              buckets[k].value = v.key.split(': ')[0]
            }
            isOpen = false
            multiSelect = true
            reorderBucketOptions(buckets, key)
            break
          case 'recordGroupNumber':
            buckets = value.filter_recordGroups.recordGroups.buckets
            for (const [k, v] of Object.entries(buckets)) {
              buckets[k].value = v.key.split(': ')[0]
            }
            label = 'Part of Record Groups'
            isOpen = false
            multiSelect = true
            reorderBucketOptions(buckets, key)
            break
          case 'dates':
            buckets = []
            label = 'Dates by Year'
            isOpen = false
            // multiSelect = true
            for (const [k, v] of Object.entries(value.buckets)) {
              buckets.push({ key: k, value: k, doc_count: v.doc_count })
            }
            reorderBucketOptions(buckets, key)
            break
          case 'objectType':
            buckets = []
            value.buckets.map((b) => {
              const newText = returnValueAndLabelFromString(b.key)
              buckets.push({
                key: newText.label,
                value: newText.val,
                doc_count: b.doc_count,
              })
            })
            isOpen = false
            label = 'File Format of Archival Materials'
            multiSelect = true
            wrapInQuotes = true
            reorderBucketOptions(buckets, key)
            break
          case 'referenceUnits':
            isOpen = false
            label = 'Location of Archival Materials'
            multiSelect = true
            wrapInQuotes = true
            buckets = value.buckets.map((b) => {
              const newText = returnValueAndLabelFromString(b.key)
              return {
                key: newText.label,
                value: newText.val,
                doc_count: b.doc_count,
              }
            })
            reorderBucketOptions(buckets, key)
            break
          default:
            label = ''
        }

        buckets?.length > 0 &&
          jsx.push(
            <div
              key={label}
              className="usa-accordion"
              aria-multiselectable="true"
            >
              <FilterGroup
                key={label}
                index={index}
                isOpen={isOpen}
                label={label}
                totalOptions={buckets.length}
              >
                <ExpandCollapseList id={`filter-${key}-list`} initial={10}>
                  {/*key === 'congressNumbers' && (
                    <BarChartCongressFilter data={buckets} />
                  )*/}
                  {buckets.map((option, index) => {
                    // Determines if a filter is currently selected,
                    // and take into account whenther multiple filters are allowed

                    const active =
                      key === 'dates'
                        ? query?.['startDate'] ===
                            (option?.value?.split('-')[0] ?? '') &&
                          query?.['endDate'] ===
                            (option?.value?.split('-')[1] ?? '')
                        : multiSelect
                        ? query?.[key]?.includes(
                            (option.value ?? option.key ?? '').replace(/,/g, '')
                          )
                        : query?.[key] ===
                          (option.value ?? option.key ?? '').replace(/,/g, '')

                    return (
                      <button
                        data-testid={`nac-filter--${key}--${convertSentenceToCamel(
                          option.key
                        )}`} //remove space
                        key={option.key}
                        onClick={() => {
                          handleFilterClick(
                            key,
                            option.value ? option.value : option.key,
                            multiSelect,
                            wrapInQuotes
                          )
                        }}
                        className={[
                          'nac-filter-item',
                          'minh-button',
                          //
                          'bg-transparent',
                          'border-0',
                          'clickable',
                          'flex-align-center',
                          'flex-row',
                          'font-sans-xs',
                          'grid-row',
                          'padding-y-1',
                          'padding-x-1',
                          'text-base-darker',
                          active ? 'text-bold' : '',
                          'text-left',
                          'width-full',
                        ].join(' ')}
                      >
                        <span
                          className={[
                            'flex-column',
                            'grid-col-auto',
                            'height-2',
                            'margin-top-2px',
                            'margin-right-1',
                            'width-2',
                          ].join(' ')}
                        >
                          {active && (
                            <Icon
                              iconName="close"
                              iconSize="xs"
                              strokeThickness="thick"
                            />
                          )}
                        </span>
                        <div className="flex-column grid-col">
                          <span className="filter-link display-inline">
                            {returnIconAndLabelFromString(option.key).label}
                          </span>
                          <span
                            className={[
                              'display-inline',
                              'font-sans-2xs',
                              'height-full',
                              'margin-left-05',
                              'text-base-dark',
                              'text-tabular',
                            ].join(' ')}
                          >
                            ({returnNumberWithCommas(option.doc_count)})
                          </span>
                        </div>
                      </button>
                    )
                  })}
                </ExpandCollapseList>
              </FilterGroup>
            </div>
          )

        index++
      })

    return jsx
  }

  if (filters) {
    return (
      <div
        className={`${className} no-print nac-search-results__filter display-flex flex-gap flex-column grid-col width-card-lg flex-align-start`}
      >
        <div className="display-flex flex-row grid-row">
          <label
            className={`flex-row grid-row flex-align-center padding-1 text-base-dark text-normal font-sans-2xs`} /*border-base border-y-1px border-left-1px border-right-0 padding-right-05 radius-left-md padding-y-1 */
            htmlFor="filters"
          >
            Filter by:
          </label>
          {/*//!allFalse(activeFilters) && (
            <button
              className="nac-link border-0 font-sans-xs margin-left-auto"
              href="#"
              onClick={() => handleResetFilters()}
            >
              Reset
            </button>
            /*)*/}
        </div>
        <SelectedFilters
          handleUpdateValue={handleUpdateValue}
        ></SelectedFilters>
        <fieldset
          role="radiogroup"
          id="filters"
          className="usa-fieldset flex-column grid-col padding-0 font-sans-2xs"
        >
          <RadioButtonField
            checked={checked}
            data-testid="nac-filter--all-records"
            groupId="filters"
            handleSetChecked={handleSetChecked}
            id="all-records"
            key="all-records"
            label={'All records'}
            value="all-records"
          />
          <RadioButtonField
            checked={checked}
            data-testid="nac-filter--available-online"
            disabled={filters.availableOnline.doc_count == 0}
            groupId="filters"
            handleSetChecked={handleSetChecked}
            id="available-online"
            key="available-online"
            label={
              <span>
                Available to access online
                {filters.availableOnline.doc_count > 0 && (
                  <span
                    className={[
                      'font-sans-2xs',
                      'height-full',
                      'margin-left-05',
                      'text-base-dark',
                      'text-normal',
                      'text-tabular',
                    ].join(' ')}
                  >
                    ({returnNumberWithCommas(filters.availableOnline.doc_count)}
                    )
                  </span>
                )}
              </span>
            }
            value="available-online"
          />
        </fieldset>
        {returnFilters()}
      </div>
    )
  } else {
    return false
  }
}

CatalogFilters.defaultProps = {
  className: '',
  filters: {},
  handleUpdateValue: () => {},
}

CatalogFilters.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.object.isRequired,
  handleUpdateValue: PropTypes.func,
}
export default CatalogFilters
