import React, { useContext, useEffect, useState } from 'react'
import '../../../../styles/index.scss'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
//Schemas
import { tagInputSchema } from '../../../../schemas/tag-input-schema'
//Context
import ContributionContext from '../../../../contexts/ContributionContext'
import DigitalObjectContext from '../../../../contexts/DigitalObjectContext'
import UserContext from '../../../../contexts/UserContext'
//Components
import LogInPrompt from '../LogInPrompt'
import { Button } from '../../../Button/Button'
import { IconLabel } from '../../../IconLabel/IconLabel'
//Hooks
import useHttp from '../../../../hooks/use-http'
import useInput from '../../../../hooks/use-input'
//Helper Functions
import { SEARCH_URL } from '../../../settings/globals'
import {
  validateInputFromSchema,
  removeExtraWhiteSpace,
  ValidationError,
} from '../../../form/Validate/Validate'
import RecordContext from '../../../../contexts/RecordContext'
import BasicLink from '../../../BasicLink'
import { ListSeparator } from '../../../ListSeparator/ListSeparator'

const TagInput = ({ tags, objectId, ...props }) => {
  const { tags: tagArray, tagsDispatch } = useContext(ContributionContext)
  const { naId } = useContext(RecordContext)
  const { loggedInUser } = useContext(UserContext)

  const [tagInputError, setTagInputError] = useState(null)

  const handleTagInputError = (error) => {
    setTagInputError(
      typeof error === 'string' ? new ValidationError(error) : error
    )
  }

  const {
    isValid: enteredTagIsValid,
    value: enteredTag,
    inputBlurHandler: tagBlurHandler,
    reset: resetTagInput,
    valueChangeHandler: tagChangedHandler,
  } = useInput(
    (value) => validateInputFromSchema(tagInputSchema, value, tags),
    handleTagInputError
  )

  const sanitizedInput = removeExtraWhiteSpace(
    DOMPurify.sanitize(enteredTag.replace(/</g, '&lt;'))
  )

  const handleTagResponse = (data) => {
    if (!data.contributionId) {
      setTagInputError('Something went wrong. Please try again.')
      return false
    }
    if (loggedInUser.role === 'administrator') {
      data.userName = 'NARA Citizen Archivist'
      data.madeByAdministrator = 1
    }
    if (
      loggedInUser.role === 'administrator' ||
      loggedInUser.role === 'moderator'
    ) {
      data.userName = loggedInUser.userName
      data.naraStaff = 1
    }
    tagsDispatch({
      type: 'ADD_TAG',
      //
      tag: data,
    })
    resetTagInput()
  }

  const { isLoading: postingTag, sendRequest: addTagRequestHandler } = useHttp(
    {
      url: `${SEARCH_URL}/tags/`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        tag: sanitizedInput,
        targetNaId: parseInt(naId),
        targetObjectId: objectId ? objectId : null,
        userId: loggedInUser?.userId,
      },
    },
    handleTagResponse,
    handleTagInputError
  )

  const formSubmitionHandler = (event) => {
    event.preventDefault()
    //Prevent submittion of invalid tag or provide console warning
    if (!enteredTagIsValid && tagInputError?.type === 'error') {
      alert(
        'Failed to submit tag: The tag did not pass validation.',
        tagInputError?.message
      )
      return
    }
    if (!sanitizedInput) {
      alert(
        'Failed to submit tag: The sanitized input resulted in an empty string.'
      )
      return
    }
    if (!naId) {
      alert(
        'Failed to submit tag: The required attribute "parentID" was not provided.'
      )
      return
    }

    addTagRequestHandler()
  }

  const errorInputClasses =
    tagInputError?.type === 'error'
      ? 'invalid border-error-dark bg-error-lighter'
      : tagInputError?.type === 'warning'
      ? 'warning border-accent-warm-dark bg-accent-warm-lighter'
      : ''
  const errorMessageClasses =
    tagInputError?.type === 'error'
      ? 'text-error-dark'
      : tagInputError?.type === 'warning'
      ? 'text-warning-dark'
      : ''

  return (
    <>
      {loggedInUser ? (
        <form id="user-tag-input" onSubmit={formSubmitionHandler}>
          <div className="display-flex flex-column">
            <div
              className={[
                'flex-align-start',
                'flex-no-wrap',
                'flex-row',
                'grid-row',
              ].join(' ')}
            >
              <label
                htmlFor={tagInputSchema['id']}
                className={['flex-column', 'grid-col', 'margin-right-1'].join(
                  ' '
                )}
              >
                <p className="usa-sr-only">{tagInputSchema['srText']}</p>
                <input
                  aria-invalid={tagInputError?.type === 'error'}
                  aria-required={tagInputSchema['required']}
                  autoComplete={tagInputSchema['autoComplete']}
                  className={[
                    'usa-input',
                    errorInputClasses,
                    //
                    'bg-white',
                    'border-base-dark',
                    'border-1px',
                    'padding-left-2',
                    'padding-right-4',
                    'margin-top-05',
                    'maxw-full',
                    'radius-md',
                  ].join(' ')}
                  data-testid="nac-tags_input"
                  id={tagInputSchema['id']}
                  maxLength={tagInputSchema['maxlength']}
                  minLength={tagInputSchema['minlength']}
                  name={tagInputSchema['id']}
                  onBlur={tagBlurHandler}
                  onChange={tagChangedHandler}
                  required={tagInputSchema['required']}
                  type={tagInputSchema['type']}
                  value={enteredTag}
                />
              </label>
              <Button
                aria-label="Add a new tag"
                data-testid="nac-tags_submit"
                disabled={
                  tagInputError?.type == 'error' || !enteredTag || postingTag
                }
                iconName="plus"
                iconSize="xs"
                size="3xs"
                type="submit"
                thin
                outline
              >
                Tag
              </Button>
            </div>
            <div className="display-inline-flex flex-column width-full">
              <p
                aria-atomic="true"
                aria-describedby={tagInputSchema['id']}
                className={[
                  errorMessageClasses,
                  //
                  tagInputError?.message ? 'active' : '',
                  'anim-duration-500ms',
                  'anim-opacity',
                  'flex-row',
                  'grid-row',
                  'font-sans-3xs',
                  'position-relative',
                  'margin-top-1',
                  'minh-2',
                ].join(' ')}
                data-testid="nac-tags_error"
                role="alert"
              >
                <IconLabel
                  color={
                    tagInputError?.type === 'error'
                      ? 'error-dark'
                      : tagInputError?.type === 'warning'
                      ? 'warning-dark'
                      : 'base-darker'
                  }
                  iconName={
                    tagInputError?.type === 'error' ||
                    tagInputError?.type === 'warning'
                      ? 'triangle-exclaim'
                      : null
                  }
                  iconSize="xs"
                  size="2xs"
                >
                  {tagInputError?.message}
                </IconLabel>
              </p>
              <div
                className={[
                  !tagInputError ? 'margin-top-neg-3' : '',
                  'font-sans-2xs',
                  'text-normal',
                ].join(' ')}
              >
                <ListSeparator color="base" distance="2px" size="xl">
                  <BasicLink
                    className={['flex-align-center'].join(' ')}
                    href="https://www.archives.gov/citizen-archivist/resources"
                  >
                    Need Help?
                  </BasicLink>
                  <BasicLink href="https://www.archives.gov/citizen-archivist/resources/tagging-policy">
                    Policy
                  </BasicLink>
                </ListSeparator>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <LogInPrompt />
      )}
    </>
  )
}

TagInput.defaultProps = {
  tags: [],
}

TagInput.propTypes = {
  objectId: PropTypes.number,
  tags: PropTypes.array,
}

export default TagInput
