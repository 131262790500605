import React, { useContext } from 'react'
import '../../../styles/index.scss'
import PropTypes from 'prop-types'
import {
  levelOfDescription,
  authorityType,
} from '../../settings/RecordPropTypes'
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs'
import { ColorPropTypes } from '../../settings/ColorPropTypes'
import { IconLabel } from '../../IconLabel/IconLabel'
import { returnIconAndLabelFromString } from '../../../helper-functions/metadata-functions'
import { PillOnlineAvailability } from '../../OnlineAvailability/PillOnlineAvailability'
import { useMemo } from 'react'
import Highlighter from '../../Highlighter/Highlighter'
import SearchContext from '../../../contexts/SearchContext'
// import Highlighter from '../../Highlighter/Highlighter'

export const PageHeader = ({
  availability,
  breadcrumbs,
  children,
  className,
  label,
  record,
  theme,
  title,
  type,
  ...props
}) => {
  const { activeQueryParams } = useContext(SearchContext) || {}
  const q = activeQueryParams.q || sessionStorage.getItem('search')
  sessionStorage.setItem('titles', title)
  const showAvailability = useMemo(() => {
    if (!record) return false
    return (
      availability &&
      record.recordType === 'description' &&
      !record.digitalObjects
    )
  }, [availability])
  return (
    <section
      className={[
        'overflow-visible',
        'page-header-wrapper',
        type == 'item' ? '' : 'shadow-xs',
        `theme-${theme}`,
        `theme-${theme}--bg`,
        `theme-${theme}--text`,
        'width-full',
        className,
      ].join(' ')}
    >
      <div
        className={[
          'page-header',
          //
          'display-flex',
          'flex-column',
          'flex-gap',
          'grid-col',
          'maxw-desktop-lg',
          'margin-x-auto',
          'width-full',
        ].join(' ')}
      >
        {breadcrumbs && breadcrumbs.length > 0 && (
          <Breadcrumbs crumbs={breadcrumbs} theme={theme} />
        )}
        <div
          className={[
            'display-flex',
            'flex-align-center',
            'flex-gap',
            'flex-row',
          ].join(' ')}
        >
          <IconLabel
            size="md"
            theme={theme}
            iconName={returnIconAndLabelFromString(type).icon}
            data-testid="nac-page-header--label"
          >
            {label ? label : type}
          </IconLabel>

          {showAvailability && (
            <PillOnlineAvailability availability={availability} />
          )}
        </div>
        <h1
          className={[
            'clearfix',
            // 'ellipsed-line-4',
            'page-title',
            'maxw-tablet-lg',
            `theme-${theme}--text`,
          ].join(' ')}
          data-testid="nac-page-header--title"
          title={title}
        >
          <Highlighter search={q}>{title}</Highlighter>
        </h1>
        {children && children}
      </div>
    </section>
  )
}

PageHeader.defaultProps = {
  theme: 'primary-lighter',
  imageCount: 0,
}

PageHeader.propTypes = {
  availability: PropTypes.string,
  breadcrumbs: PropTypes.array,
  className: PropTypes.string,
  label: PropTypes.string,
  /**
   * The entire data object for a single record
   */
  record: PropTypes.object,
  type: PropTypes.oneOf(levelOfDescription.concat(authorityType)),
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Defines the theme of the header. This is based on a subset of the USWDS color utilities (https://designsystem.digital.gov/utilities/color/)
   */
  theme: PropTypes.oneOf(ColorPropTypes),
}
