import DOMPurify from 'dompurify'
import queryString from 'query-string'
import { isNumericValue, isStringValue } from './number-functions'
import { DEFAULT_LIMIT, DEFAULT_PAGE } from '../components/settings/globals'
import { generalRecordsTypes } from '../components/settings/RecordPropTypes'

export const paramExistsInQueryString = (param, params) => {
  let { validatedParams } = returnValidatedUrlParameters(
    queryString.parse(params || location.search)
  )
  return validatedParams[param] && validatedParams[param] !== ''
}

export const removeParamsAndReturnSearchString = (paramsToRemove, params) => {
  let { validatedParams } = returnValidatedUrlParameters(
    queryString.parse(params || location.search)
  )
  validatedParams = removeParamsFromObject(paramsToRemove, validatedParams)
  let paramString
  if (validatedParams && queryString.stringify(validatedParams) !== '')
    paramString = `?${queryString.stringify(validatedParams)}`
  else paramString = ''

  return paramString
}

/**
 * Remove any unwanted parameters from an array
 * @param   {Array}  paramsToRemove String array of each parameter name to be removed
 * @param   {Object} params         Key-value pairs, where keys are the parameter name
 * @returns {Object}                Filtered object
 */
export const removeParamsFromObject = (paramsToRemove, params) => {
  if (!params) return false
  if (!paramsToRemove) return params
  paramsToRemove.map((param) => {
    if (params[param]) delete params[param]
  })
  return params
}

/**
 * Make sure each URL parameter has expected property types
 * @param   {Object} params Key-value pairs, where keys are the parameter name
 * @returns {Object}        Filtered object containing entries which pass validation
 */
export const returnValidatedUrlParameters = (params) => {
  let validatedParams = {}
  let paramsToRemove = []
  paramPropTypes.map((type) => {
    for (const [key, value] of Object.entries(params)) {
      if (type.name == key) {
        const sanitizedParam = DOMPurify.sanitize(value)
        if (!sanitizedParam) return false
        //Otherwise, guess how to handle it.
        if (type.propType == 'number') {
          if (isNumericValue(sanitizedParam))
            validatedParams[key] = parseInt(sanitizedParam)
          else {
            console.warn(
              `Warning: Failed prop type: Invalid prop ${key} of type '${typeof sanitizedParam}' supplied to the URL parameters, expected 'number'.`
            )
            paramsToRemove.push(key)
          }
          //Check that expected strings are strings
        } else if (type.propType == 'string') {
          if (isStringValue(String(sanitizedParam)))
            validatedParams[key] = sanitizedParam
          else {
            paramsToRemove.push(key)
            console.warn(
              `Warning: Failed prop type: Invalid prop ${key} of type '${
                isNumericValue(sanitizedParam)
                  ? 'number'
                  : typeof sanitizedParam
              }'  supplied to the URL parameters, expected 'string'.`
            )
          }
        }
        //Check that expected numbers are numeric
        else if (type.propType == 'oneOf') {
          if (type.oneOf.indexOf(sanitizedParam) > -1)
            validatedParams[key] = String(sanitizedParam)
          else {
            paramsToRemove.push(key)
            console.warn(
              `Warning: Failed prop type: Invalid prop ${key} of string '${sanitizedParam}' 
              supplied to the URL parameters, expected one of ${type.oneOf.join(
                ', '
              )}.`
            )
          }
        }
      }
    }
  })
  return { validatedParams, paramsToRemove }
}

/**
 * Array of expected param names and propTypes
 */
export const paramPropTypes = [
  { name: 'ancestorNaId', propType: 'number' },
  { name: 'availableOnline', propType: 'bool' },
  {
    name: 'collectionIdentifier',
    propType: 'string',
  },
  { name: 'contributionId', propType: 'string' },
  {
    name: 'dataSource',
    propType: 'string',
    oneOf: ['authority', 'description'],
  },
  {
    name: 'levelOfDescription',
    propType: 'string',
    oneOf: ['collection', 'fileUnit', 'item', 'recordGroup', 'series'],
  },
  {
    name: 'limit',
    propType: 'number',
  },
  {
    name: 'naId',
    propType: 'string',
  },
  { name: 'objectId', propType: 'number' },
  { name: 'objectPage', propType: 'number' },
  {
    name: 'objectPanel',
    propType: 'oneOf',
    oneOf: [
      'comment',
      'tag',
      'transcription',
      'extracted',
      'extractedText',
      'combo',
    ],
  },
  {
    name: 'page',
    propType: 'number',
  },
  {
    name: 'q',
    propType: 'string',
  },
  {
    name: 'recordGroupNumber',
    propType: 'string',
  },
  { name: 'title', propType: 'string' },
  {
    name: 'typeOfMaterials',
    propType: 'string',
    oneOf: generalRecordsTypes,
  },
  { name: 'organizationNaId', propType: 'number' },
]

export const returnQueryStringFromObject = (paramObject) => {
  let queryString = `page=${paramObject.page || DEFAULT_PAGE}&limit=${
    paramObject.limit || DEFAULT_LIMIT
  }`

  //Build the param string to query
  Object.entries(paramObject).map(([key, value]) => {
    key = key === 'maskedQ' ? 'q' : key
    if (key !== 'page' && key !== 'limit')
      queryString += `&${key}=${DOMPurify.sanitize(value)}`
  })

  // resolves ios safari issue of not being able to properly encode this character thus resulting in a broken result page
  queryString = queryString.replace('’', "'")

  return queryString
}

export const returnAbbreviatedQueryStringFromObject = (paramObject) => {
  let queryString = `${returnQueryStringFromObject(
    paramObject
  )}&abbreviated=true&debug=true`

  // resolves ios safari issue of not being able to properly encode this character thus resulting in a broken result page
  queryString = queryString.replace('’', "'")

  return queryString
}

export const returnTotalResultIndex = (
  index,
  limit = DEFAULT_LIMIT,
  page = DEFAULT_PAGE
) => {
  return parseInt(page) * limit - limit + parseInt(index) + 1
}
