import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import queryString from 'query-string'
import { useSafeAsync } from '../../helper-functions/query-functions'
//Settings
import { DEFAULT_MAX_CONTENT_WIDTH, SEARCH_URL } from '../settings/globals'
//Context
import SearchContext from '../../contexts/SearchContext'
//Components
import { Alert } from '../Alert/Alert'
import CatalogFilters from '../form/filters/CatalogFilters'
import CatalogSort from '../form/CatalogSort'
import ErrorPage from '../Pages/ErrorPage/ErrorPage'
import { FilterButton } from '../Button/variants/FilterButton'
import Loader from '../utilities/Loader'
import NoResultFeedback from '../NoResultsFeedback'
import Pagination from '../utilities/Pagination'
import ResultsPerPage from '../form/ResultsPerPage'
import Search from '../Search/Search'
import Summary from '../Templates/Results/results-summary'
import { IconLabel } from '../IconLabel/IconLabel'
import { ListSeparator } from '../ListSeparator/ListSeparator'
import { PageHeader } from '../Templates/PageHeader/PageHeader'
import { Results } from '../Templates/Results/Results'
import { ResultsLoader } from '../ContentLoaders/ResultsLoader'
//Helpers
import { returnIconAndLabelFromString } from '../../helper-functions/metadata-functions'
import { updateURLParam } from '../../helper-functions/queryHandlers'
import { returnSortRequest } from '../../helper-functions/query-functions'
import { returnAbbreviatedQueryStringFromObject } from '../../helper-functions/param-functions'
import { returnNumberWithCommas } from '../../helper-functions/number-functions'
import { ButtonSharePage } from '../Button/variants/ButtonSharePage'
import { ButtonDisplayOptions } from '../Button/variants/ButtonDisplayOptions'
import useScreenSize from '../../hooks/use-screen-size'

const SearchWithin = ({ ...props }) => {
  const searchContext = useContext(SearchContext)
  const safeAsync = useSafeAsync()
  const { state } = useLocation()
  const navigate = useNavigate()
  const { naId } = useParams()

  const { pathname, search } = useLocation()
  const q = queryString.parse(search)
  const [query, setQuery] = useState(q || null)
  const [controlGroup, setControlGroup] = useState('*')

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [within, setWithin] = useState(null)

  useEffect(() => {
    const data = within?.title
    const called = sessionStorage.getItem('search')
    if (data) {
      sessionStorage.setItem('titles', data)
    } else {
      sessionStorage.setItem('titles', called)
    }
  })
  const [resultsLoading, setResultsLoading] = useState(true)
  const [results, setResults] = useState(null)
  const [resultsError, setResultsError] = useState(null)
  const [filters, setFilters] = useState(null)
  const [sort, setSort] = useState(DOMPurify.sanitize(q.sort))
  const [contents, setContents] = useState(null)

  const [total, setTotal] = useState(null)
  const [pages, setPages] = useState(null)
  const [time, setTime] = useState(null)
  const [timedOut, setTimedOut] = useState(false)
  const [page, setPage] = useState(DOMPurify.sanitize(parseInt(q.page)) || 1)
  const [limit, setLimit] = useState(
    DOMPurify.sanitize(parseInt(q.limit)) || 20
  )
  const [naIds, setNaIds] = useState(null)
  const { screenSize } = useScreenSize()

  useEffect(() => {
    if (loading) return false
    window.scrollTo(0, 0)
  }, [loading])

  const handleSetParentResults = () => {
    setLoading(true)
    setWithin({})
    setError(null)
    let controlGroup = ''
    if (state && state.controlGroup)
      controlGroup = `&controlGroup=${state.controlGroup}`
    safeAsync(
      fetch(`${SEARCH_URL}/records/search?naId=${naId}${controlGroup}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          throw response
        })
        .then((data) => {
          let resulting_record = []
          data.body.hits.hits.map((result) => {
            setControlGroup(result._source?.metadata?.controlGroup?.naId)
            resulting_record = result?._source?.record
          })
          if (resulting_record.length == 0) throw '404'
          else {
            setWithin(resulting_record)
          }
          setLoading(false)
        })
        .catch((error) => {
          if (error == '404') {
            error = {
              title: 'Page not found',
              status: 404,
              description: 'We could not find the page you are looking for.',
              id: 'PAGENOTFOUND',
            }
          }
          console.error('Error fetching data: ', error)
          setError(error)
        })
    )
  }

  const handleSetChildrenResults = (params) => {
    setResults(null)
    setResultsError(null)
    setResultsLoading(true)
    const sent = new Date().getTime()

    // Ensure we remove the ancestorNaId parameter before using it in our query
    params = params.replace(`&ancestorNaId=${naId}`, '')

    console.log(params)

    safeAsync(
      fetch(
        `${SEARCH_URL}/records/search?ancestorNaId=${naId}&controlGroup=${controlGroup}&${params}&datesAgg=true`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw response
          }
          return response.json()
        })
        .then((data) => {
          const count = data.body.hits.total.value
          data.body.timed_out == true ? setTimedOut(true) : setTimedOut(false)
          if (timedOut && count == 0) throw 'NO_RESULTS_TIMED_OUT'
          if (page * limit - limit > Math.ceil(count)) {
            throw 'NO_MORE_RESULTS'
          } else {
            const received = new Date().getTime()
            let results = []
            data.body.hits.hits.map((result) => {
              let record = { ...result._source.record, ...result.fields }
              results.push(record)
            })
            const naIds_list = []
            data.body.aggregations.naIds?.buckets?.map(({ key }) =>
              naIds_list.push(key)
            )
            setNaIds(naIds_list)
            delete data.body.aggregations.naIds
            //dataSource will always equal "descriptions" when searching within the archival hierarchy, so let's remove it.
            delete data.body.aggregations.dataSource
            delete data.body.aggregations.digitalObjectCount
            setResults(results)
            setFilters(data.body.aggregations)
            setTime(((received - sent) / 1000.0).toFixed(2))
            setTotal(count)
            setPages(Math.ceil(count / limit))
            setQuery(q)
            setPage(DOMPurify.sanitize(parseInt(q.page)) || 1)
            setLimit(DOMPurify.sanitize(parseInt(q.limit)) || 20)
            setSort(DOMPurify.sanitize(q.sort) || 'naId:asc')

            let newParams = Object.assign({}, searchContext.activeQueryParams)
            newParams.ancestorNaId =
              naId || pathname.substring(pathname.lastIndexOf('/') + 1)
            searchContext.setActiveQueryParams(newParams)
            searchContext.setTotal(count)
            searchContext.setActiveResults(results)

            setResultsLoading(false)
          }
        })
        .catch((error) => {
          setLoading(false)
          if (typeof error.text === 'function') {
            error.text().then((errorMessage) => {
              setError(JSON.parse(errorMessage))
            })
          } else if (error == 'NO_MORE_RESULTS') {
            setError({
              title: 'Results not found',
              status: 404,
              description:
                'There are no more results available for this search.',
              id: 'NO_RESULTS',
            })
          } else if (error == 'NO_RESULTS_TIMED_OUT') {
            setError({
              title: 'Timed out with no results.',
              status: 408,
              description:
                'This search took too long, and no results were found.',
              id: 'NO_RESULTS_TIMED_OUT',
            })
          } else {
            console.error('Error fetching data: ', error)
            setError(error)
          }
        })
    )
  }

  useEffect(() => {
    handleSetParentResults()
    searchContext.setSearchQuery('')
  }, [])

  const handleSubmit = (event, value) => {
    event.preventDefault()
    let searchString = `?q=${encodeURIComponent(value)}`

    navigate({
      search: searchString,
    })
    setQuery(value)
  }

  const handleUpdateSort = (value) => {
    setSort(returnSortRequest(DOMPurify.sanitize(value)))
    updateURLParam(query, navigate, 'sort', value)
    // if (value == 'relevant') updateURLParam(query, navigate, 'sort', 'relevant')
    // else updateURLParam(query, navigate, 'sort', value)
  }

  /* PAGINATION FUNCTIONS
   * For use with the Pagination component.
   * Defines functionality when the next/prev pagination buttons are clicked,
   * * or an input is entered into the pagination input field.
   * */
  const resetPage = () => {
    updateURLParam(query, navigate, 'page', 1, true)
  }
  const updatePage = (page) => {
    if (page < 0) return false
    if (page > pages) {
      updateURLParam(query, navigate, 'page', pages)
      setPage(parseInt(pages))
    } else setPage(parseInt(page))
    if (page == 1) resetPage()
    else updateURLParam(query, navigate, 'page', page)
  }
  const nextPage = () => {
    if (parseInt(page) + 1 > pages) return false
    if (parseInt(page) < pages) {
      setPage(parseInt(page) + 1)
      updateURLParam(query, navigate, 'page', parseInt(page) + 1)
    }
  }
  const previousPage = () => {
    if (page > 1) {
      setPage(page - 1)
      if (page - 1 == 1) resetPage()
      else updateURLParam(query, navigate, 'page', page - 1)
    }
  }

  const handleUpdateLimit = (value) => {
    setLimit(value)
    const reset = page * value - value > total
    updateURLParam(query, navigate, 'limit', value, reset)
  }

  const handleUpdateFilters = (param, value, remove = false) => {
    setPage(1)
    if (remove) value = ''
    if (param === 'dates') {
      if (value.indexOf('-') > -1) {
        const dates = value.split('-')
        updateURLParam(query, navigate, param, dates, true)
      }
    } else {
      updateURLParam(query, navigate, param, value, true)
    }
  }

  useEffect(() => {
    let q = queryString.parse(search)
    setQuery(q)
    let page = DOMPurify.sanitize(parseInt(q.page)) || 1
    let limit = DOMPurify.sanitize(parseInt(q.limit)) || 20
    setPage(page)
    setLimit(limit)

    if (q.sort == null) {
      q.sort = 'naId:asc'
    } else if (q.sort == 'relevant') {
      delete q.sort
    }

    setSort(DOMPurify.sanitize(q.sort))

    //Ensure the query string includes page and limit (even if they are not specified in the URL)
    //and ensure the params value **doesn't** begin with an "&"

    const params = returnAbbreviatedQueryStringFromObject(q)
    handleSetChildrenResults(params)
  }, [search])

  return error ? (
    <ErrorPage error={error} />
  ) : loading ? (
    <main
      className={[
        'height-viewport',
        'flex-align-center',
        'flex-justify-center',
        'flex-row',
        'grid-row',
      ].join(' ')}
    >
      <Loader />
    </main>
  ) : (
    <main className={['width-full', 'bg-base-lightest'].join(' ')}>
      <div
        className={[
          'bg-primary-dark',
          'padding-x-1 tablet:padding-x-3',
          'padding-y-05',
          'width-full',
        ].join(' ')}
      >
        <div
          className={[
            'display-flex',
            'flex-align-center',
            'flex-gap-xs tablet-lg:flex-gap-sm',
            'flex-justify-center',
            'flex-no-wrap',
            'flex-row',
            'font-sans-2xs',
            'margin-x-auto',
            `maxw-${DEFAULT_MAX_CONTENT_WIDTH}`,
            'minh-button',
            'text-base-lightest',
            'width-full',
          ].join(' ')}
        >
          <div className={['margin-left-auto'].join(' ')}>
            <div
              className={[
                'display-flex',
                'flex-gap-xs',
                'flex-row',
                'margin-left-auto',
                'text-right',
              ].join(' ')}
            >
              <ButtonSharePage
                data-testid="nac-search--share-page"
                theme="primary-lighter"
              />
              <ButtonDisplayOptions
                data-testid="nac-search--share-page"
                theme="primary-lighter"
              />
            </div>
          </div>
        </div>
      </div>
      <PageHeader
        theme="primary"
        breadcrumbs={[
          {
            controlGroup: String(controlGroup),
            link: `/id/${String(within.naId)}`,
            title: 'View full description',
            type: 'prev',
          },
          {
            title: 'Search within',
          },
        ]}
        type={within.levelOfDescription}
        label={
          within.levelOfDescription == 'recordGroup'
            ? `Record Group ${within.recordGroupNumber}`
            : within.levelOfDescription == 'collection'
            ? `Collection ${within.collectionIdentifier}`
            : within.levelOfDescription
        }
        title={within.title}
      >
        <div
          className={[
            'margin-bottom-1',
            'display-inline-flex',
            'flex-wrap',
            'flex-align-center',
          ].join(' ')}
        >
          <span
            className={[
              'text-italic',
              'font-sans-sm',
              'theme-primary--accent',
              'margin-right-1',
              'text-bold',
              'font-sans-md',
            ].join(' ')}
          >
            Includes:
          </span>
          {/*contents && (*/}
          <ListSeparator
            size="md"
            delimeter="forward-slash"
            distance="1"
            align="text-top"
          >
            {within.seriesCount && (
              <IconLabel
                iconName="box"
                // iconSize="xs"
                // size="2xs"
                theme="primary"
              >
                {returnNumberWithCommas(within.seriesCount)} Series
              </IconLabel>
            )}
            {within.fileUnitCount && (
              <IconLabel
                iconName="folder"
                // iconSize="xs"
                // size="2xs"
                theme="primary"
              >
                {returnNumberWithCommas(within.fileUnitCount)} File Unit
                {within.fileUnitCount > 1 ? 's' : ''}
              </IconLabel>
            )}
            {within.itemCount && (
              <IconLabel
                iconName="items"
                // iconSize="xs"
                // size="2xs"
                theme="primary"
              >
                {returnNumberWithCommas(within.itemCount)} Item
                {within.itemCount > 1 ? 's' : ''}
              </IconLabel>
            )}
            {/*contents.map(({ count, label }) => (
                <IconLabel
                  key={label}
                  size="sm"
                  theme="primary"
                  iconName={returnIconAndLabelFromString(label).icon}
                >
                  {returnNumberWithCommas(count)} {returnIconAndLabelFromString(label).label}
                  {count > 1 && label !== 'series' ? 's' : ''}
                </IconLabel>
              ))*/}
          </ListSeparator>
          {/*})*/}
        </div>
        <Search
          autoComplete="off"
          isSearchWithin
          onSubmit={handleSubmit}
          theme="primary-darker"
          value={sessionStorage.getItem('setSearch') || ''}
          placeholder={`Search within this ${
            returnIconAndLabelFromString(within.levelOfDescription).label
          }`}
        />
      </PageHeader>
      {resultsError ? (
        <ErrorPage error={resultsError} />
      ) : (!!query.q || !!query.title || !!query.naId) && total == 0 ? (
        <NoResultFeedback term={query.q} />
      ) : (
        <section
          className={[
            'display-flex',
            'flex-gap',
            'flex-column',
            'grid-col',
            'margin-x-auto',
            'maxw-desktop-lg',
            'padding-x-3',
            'width-full',
          ].join(' ')}
        >
          {resultsLoading ? (
            <ResultsLoader />
          ) : (
            <>
              <div
                className={[
                  'border-base-lighter',
                  'border-bottom-1px',
                  'display-flex',
                  'flex-align-center',
                  'flex-row',
                  'grid-row',
                  'padding-y-2',
                  //'tablet:flex-row',
                  //'tablet:grid-row',
                  'width-full',
                ].join(' ')}
              >
                <Summary
                  label={
                    returnIconAndLabelFromString(within.levelOfDescription)
                      .label
                  }
                  limit={parseInt(limit)}
                  page={parseInt(page)}
                  term={query.q}
                  time={time}
                  total={parseInt(total)}
                />
                <div className={['display-flex', 'margin-left-auto'].join(' ')}>
                  <ResultsPerPage
                    active={limit.toString()}
                    handleUpdateValue={handleUpdateLimit}
                  />
                </div>
              </div>
              <div
                className={[
                  'display-flex',
                  'flex-gap-lg',
                  'flex-column',
                  'grid-col',
                  'margin-bottom-4',
                  'tablet:flex-row',
                  'tablet:grid-row',
                  'width-full',
                ].join(' ')}
              >
                {filters && screenSize === 'desktop' && (
                  <CatalogFilters
                    className={['display-none', 'tablet:display-block'].join(
                      ' '
                    )}
                    filters={filters}
                    handleUpdateValue={handleUpdateFilters}
                  ></CatalogFilters>
                )}
                <div
                  className={[
                    'display-flex',
                    'flex-gap',
                    'flex-column',
                    'grid-col',
                  ].join(' ')}
                >
                  <div
                    className={['display-flex', 'flex-row', 'grid-row'].join(
                      ' '
                    )}
                  >
                    <div
                      className={[
                        'display-flex',
                        screenSize !== 'desktop' ? 'flex-wrap' : '',
                        'margin-right-auto',
                      ].join(' ')}
                    >
                      {screenSize !== 'desktop' ? (
                        <FilterButton
                          filters={filters}
                          handleUpdateFilters={handleUpdateFilters}
                          loading={loading}
                        />
                      ) : (
                        ''
                      )}
                      <div className="display-flex">
                        <CatalogSort
                          active={sort}
                          handleUpdateValue={handleUpdateSort}
                        />
                      </div>
                    </div>
                    <div className={['margin-left-auto'].join(' ')}>
                      <Pagination
                        navLabel="Search results page navigation (top)"
                        current={parseInt(page)}
                        handleNextObject={nextPage}
                        handlePrevObject={previousPage}
                        id="results-top"
                        //outline
                        reduced
                        textOnly
                        theme="base-darker"
                        total={pages}
                        updateCurrentObject={updatePage}
                      />
                    </div>
                  </div>
                  {timedOut && (
                    <Alert type="warning">
                      The search took too long and timed out. Only some results
                      are listed here. Please refine your search, and try again.
                    </Alert>
                  )}
                  {results && (
                    <Results
                      data-page-total={pages}
                      data-result-total={searchContext.total}
                      data-testid="nac-results"
                      limit={parseInt(limit)}
                      page={parseInt(page)}
                      results={results}
                      total={searchContext.total}
                    ></Results>
                  )}
                  <div
                    className={['display-flex', 'flex-row', 'grid-row'].join(
                      ' '
                    )}
                  >
                    {pages > 1 && (
                      <div className={['margin-left-auto'].join(' ')}>
                        <Pagination
                          navLabel="Search results page navigation (bottom)"
                          current={parseInt(page)}
                          handleNextObject={nextPage}
                          handlePrevObject={previousPage}
                          id="results-bottom"
                          //outline
                          reduced
                          textOnly
                          theme="base-darker"
                          total={pages}
                          updateCurrentObject={updatePage}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </section>
      )}
    </main>
  )
}

SearchWithin.defaultProps = {}

SearchWithin.propTypes = {}

export default SearchWithin
