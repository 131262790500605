import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { DEFAULT_PAGE, SEARCH_URL } from '../settings/globals'
import useHttp from '../../hooks/use-http'
import RecordContext from '../../contexts/RecordContext'
import useUrlParams from '../../hooks/use-url-params'
import DOMPurify from 'dompurify'
import {
  audioTypes,
  imageTypes,
  videoTypes,
} from '../../helper-functions/metadata-functions'

const useDescriptionNav = () => {
  /** Context */
  const {
    activeContributionId,
    setActiveContributionId,
    activeObjectPage,
    setActiveObjectPage,
    setActiveObjectType,
    setActivePanel,
    error,
    record,
    loadingRecord: loading,
    setLoadingRecord,
    setRecord,
    setError,
  } = useContext(RecordContext)

  /** React Hooks */
  const { naId } = useParams()
  const { state } = useLocation()
  const { from, results, prevPath } = state || {}
  const { pathname, search } = from || {}
  let { index = 1, naIds = [], total } = results || {}
  const navigate = useNavigate()
  const { updateParams, removeParams, params } = useUrlParams(state)

  /** Custom State */
  const [filteredObjects, setFilteredObjects] = useState(null)
  const [offset, setOffset] = useState(index)
  const [backLink, setBackLink] = useState(
    from ? { pathname: pathname, search: search } : null
  )
  const [requestParams, setRequestParams] = useState(null)

  /* Ensure displayed objects do not include certain file types */
  useEffect(() => {
    if (!record) return false
    let displayObjects = record.digitalObjects?.filter(
      ({ objectType, objectUrl }) =>
        objectUrl &&
        (imageTypes.some((element) => objectType?.includes(element)) ||
          videoTypes.some((element) => objectType?.includes(element)) ||
          audioTypes.some((element) => objectType?.includes(element)))
    )
    setFilteredObjects(displayObjects)
  }, [record])

  useEffect(() => {
    if (prevPath) {
      setOffset(index || 1)
      setBackLink(from ? { pathname: pathname, search: search } : null)
    }
  }, [naId])

  useEffect(() => {
    if (prevPath) {
      setOffset(index || 1)
      setBackLink(from ? { pathname: pathname, search: search } : null)
    }
  }, [index, pathname, search])

  useEffect(() => {
    setBackLink(from ? { pathname: pathname, search: search } : null)
  }, [from])

  useEffect(() => {
    let ancestorId = null
    if (
      state?.from?.pathname?.indexOf('search-within') > -1 &&
      search.indexOf('ancestorNaId') == -1
    ) {
      ancestorId = `&ancestorNaId=${state?.from?.pathname?.split('/')[2]}`
    }

    let params = search
      ?.replace(/page=\w+/, '')
      ?.replace(/limit=\w+/, '')
      ?.replace(/&&/g, '&')
      ?.replace(/%3D/g, '=')
      ?.replace(/%26/g, '&')
    if (offset && params)
      setRequestParams(
        `${params?.substring(1)}&limit=1&page=${offset}${ancestorId || ''}`
      )
  }, [offset])

  useEffect(() => {
    if (record?.naId == naId) return false
    const abortController = new AbortController()
    if (naId) {
      setLoadingRecord(true)
      requestRecordFromNaId(abortController)
    }
    return () => {
      abortController.abort()
    }
  }, [naId])

  useEffect(() => {
    const abortController = new AbortController()
    if (offset !== index) {
      setLoadingRecord(true)
      requestRecordFromNavHandler(abortController)
    }
    return () => {
      abortController.abort()
    }
  }, [requestParams])

  const prevHandler = () => {
    if (parseInt(offset) === 1) return false
    setOffset(offset - 1)
  }

  const nextHandler = () => {
    if (parseInt(offset) === total) return false
    setOffset(offset + 1)
  }

  const responseHandler = (record) => {
    let flattenedRecord
    record.body.hits.hits.map((rec) => {
      flattenedRecord = rec._source.record
    })

    setRecord(flattenedRecord)
    // Only push to history when the description nav has been interacted with

    if (index !== offset) {
      navigate(`/id/${flattenedRecord.naId}`, {
        state: {
          ...state,
          results: { ...results, index: offset },
          prevPath: location.pathname,
        },
      })
    }

    if (!flattenedRecord)
      setError({
        title: 'Page not found',
        status: 404,
        description: 'We could not find the page you are looking for.',
        id: 'PAGE_NOT_FOUND',
      })

    setTimeout(() => {
      setLoadingRecord(false)
    }, 500)
  }

  const errorHandler = (error) => {
    if (!error) return false
    if (typeof error.text === 'function') {
      error.text().then((errorMessage) => {
        setError(JSON.parse(errorMessage))
      })
    } else if (error.response || error.status) setError(error)
  }

  const { sendRequest: requestRecordFromNavHandler } = useHttp(
    {
      url: `${SEARCH_URL}/records/search?${requestParams}&includeExtractedText=true&includeOtherExtractedText=true`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    responseHandler,
    errorHandler
  )

  const { sendRequest: requestRecordFromNaId } = useHttp(
    {
      url: `${SEARCH_URL}/records/search?naId_is=${naId}&allowLegacyOrgNames=true&includeExtractedText=true&includeOtherExtractedText=true`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    responseHandler,
    errorHandler
  )

  useEffect(() => {
    if (!filteredObjects || filteredObjects.length == 0) return false
    if (loading) return false

    //If contribution ID exists, check if it is valid
    if (params.contributionId) {
      validateContributionParams()
    } else {
      validateObjectParams(filteredObjects)
    }

    //Final check to ensure URL page params matches page state variable
    const urlPage = parseInt(DOMPurify.sanitize(params.objectPage))
    if (!urlPage) setActiveObjectPage(1)
    else setActiveObjectPage(urlPage)

    handleSetActiveObjectType()
    return () => {
      setActiveObjectPage(1)
      // setActiveObjectPage(urlPage)
    }
  }, [loading, filteredObjects])

  // /* When the display or active object changes, ensure the relevant thumbnail is in view. */
  useEffect(() => {
    if (!activeObjectPage) return false
    const active = document.getElementById(`object-thumb--${activeObjectPage}`)
    if (!active) return false

    active.scrollIntoView({
      behavior: 'auto',
      block: 'nearest',
      inline: 'start',
    })
    handleSetActiveObjectType()
  }, [activeObjectPage])

  /**
   * Checks if any digital object contribution ID matched the URL contributionId parameter
   * @param {Object} objects Filtered response from the initial fetch request for digital objects
   */

  const validateContributionParams = () => {
    let contributionExists = false
    filteredObjects?.map((obj, index) => {
      if (obj.contributions) {
        obj.contributions?.map((contribution) => {
          //If the contribution ID from the URL matches a contribution ID from the results
          //use this match to set the states
          if (params.contributionId == contribution.contributionId) {
            updateParams({
              objectPage: index + 1,
              objectPanel: contribution.contributionType,
            })
            setActiveObjectPage(index + 1)
            setActivePanel(contribution.contributionType)
            setActiveContributionId(contribution.contributionId)

            contributionExists = true
          }
        })
      }
    })
    //If the contribution ID parameter is not valid,
    //validate any remaining parameters
    if (contributionExists) {
      params.objectId && removeParams(['objectId'])
    } else {
      record?.contributions?.map((contribution, index) => {
        if (params.contributionId == contribution.contributionId) {
          setActiveContributionId(contribution.contributionId)
          contributionExists = true
        }
      })
    }
    if (!contributionExists) {
      removeParams(['contributionId'])
      validateObjectParams(record?.digitalObjects)
    }
  }

  /**
   * Checks if any digital objects matched the URL objectId, objectPage, and or objectPanel parameters
   * @param {Object} objects Filtered response from the initial fetch request for digital objects
   */
  const validateObjectParams = (objects) => {
    let objectExists = false
    if (params.objectId) {
      objects?.map((obj, index) => {
        if (objectIdExists(obj, index)) {
          objectExists = true
          updateParams({
            objectPage: index,
          })
          removeParams(['objectId'])
        }
      })
    }
    if (params.objectPage) {
      if (objectPageExists(params.objectPage, objects)) {
        setActiveObjectPage(params.objectPage)
      } else {
        setActiveObjectPage(1)
        removeParams(['objectPage'])
      }
    }
    if (params.objectPanel) {
      setActivePanel(params.objectPanel)
    } else {
      setActivePanel(null)
      removeParams(['objectPanel'])
    }
    if (!objectExists) removeParams(['objectId'])
  }

  /**
   *
   * @param {Object} objects Single digital object
   * @param {Number} index   Specific index of this object within the digitalObjects array
   * @returns                Returns 'true' if parameter ID matches either an objectId field
   *                         in a response object, or as an existing page number
   *                         Returns 'false' otherwise
   */
  const objectIdExists = (obj, index) => {
    if (params.objectId == obj.objectId) return true
    else if (params.objectId == index + 1) return true
    else return false
  }

  /**
   *
   * @param {Number} length Number of objects
   * @returns
   */
  const objectPageExists = (page, objects) => {
    if (page === 0 || page > objects.length) return false
    else return true
  }

  const handleSetActiveObjectType = () => {
    if (!activeObjectPage || !filteredObjects)
      return setActiveObjectType('Object')
    else if (
      imageTypes.some((element) =>
        filteredObjects[activeObjectPage - 1]?.objectType?.includes(element)
      )
    )
      return setActiveObjectType('Image')
    else if (
      videoTypes.some((element) =>
        filteredObjects[activeObjectPage - 1]?.objectType?.includes(element)
      )
    )
      return setActiveObjectType('Video')
    else if (
      audioTypes.some((element) =>
        filteredObjects[activeObjectPage - 1]?.objectType?.includes(element)
      )
    )
      return setActiveObjectType('Audio File')
  }

  // Set state on params change
  useEffect(() => {
    if (params.objectPage === DEFAULT_PAGE) removeParams(['objectPage'])
  }, [params])

  return {
    backLink,
    nextHandler,
    prevHandler,
    resultIndex: offset,
    total,
  }
}
export default useDescriptionNav
