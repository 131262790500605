import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import '../../../styles/index.scss'
import PropTypes from 'prop-types'
//Context
import RecordContext from '../../../contexts/RecordContext'
//Components
import { SectionHeading } from '../../SectionHeading/SectionHeading'
import { Table } from '../../Table/Table'
import AllCopiesModal from './all_copies_modal'
//Hooks
import Card from '../../Card/Card'
import DetailsModal from './details_modal'
import ExpandCollapseList from '../../utilities/ExpandCollapseList'
import Prose from '../../utilities/Prose'
import Tooltip from '../../Tooltip/Tooltip'
import { Alert } from '../../Alert/Alert'
import { Button } from '../../Button/Button'
import { Icon } from '../../Icon/Icon'
import { ListSeparator } from '../../ListSeparator/ListSeparator'
//Helpers
import { returnHMSFromSeconds } from '../../../helper-functions/date-functions'
import { returnNumberWithCommas } from '../../../helper-functions/number-functions'
import Highlighter from '../../Highlighter/Highlighter'
import SearchContext from '../../../contexts/SearchContext'

export const archivedCopySortOrder = {
  reference: 1,
  reproductionreference: 2,
  preservationreproductionreference: 3,
  preservationreference: 4,
  reproduction: 5,
  preservationreproduction: 6,
  preservation: 7,
}
export const returnCustomOrder = (order, key, direction = 'asc') => {
  if (key == 'index') {
    return function (a, b) {
      let first = a.toLowerCase().replace(/-/g, '')
      let second = b.toLowerCase().replace(/-/g, '')
      const firstnum = first in order ? order[first] : 8
      const secondnum = second in order ? order[second] : 8

      let result = 0
      if (firstnum < secondnum) result = -1
      else if (firstnum > secondnum) result = 1
      return direction === 'desc' ? ~result : result
    }
  } else {
    return function (a, b) {
      if (
        !Object.prototype.hasOwnProperty.call(a, key) ||
        !Object.prototype.hasOwnProperty.call(b, key)
      )
        return 0
      let first = a[key].toLowerCase().replace(/-/g, '')
      let second = b[key].toLowerCase().replace(/-/g, '')
      const firstnum = first in order ? order[first] : 8
      const secondnum = second in order ? order[second] : 8

      let result = 0
      if (firstnum < secondnum) result = -1
      else if (firstnum > secondnum) result = 1
      return direction === 'desc' ? ~result : result
    }
  }
}

const SectionArchivedCopies = ({ ...props }) => {
  const { record } = useContext(RecordContext) || {}
  const { activeQueryParams } = useContext(SearchContext) || {}
  const q = activeQueryParams.q || sessionStorage.getItem('search')

  //  const referenceUnits = useMemo(() => {
  //    if (record.referenceUnits) {
  //      return record.referenceUnits
  //    }
  //    return false
  //  }, [record])

  //  if (!referenceUnits) return false

  //  const { sortedArray, count } = useHighlightList(
  //    q,
  //    referenceUnits,
  //    [
  //      'name',
  //      'mailCode',
  //      'address1',
  //      'address2',
  //      'city',
  //      'state',
  //      'postalCode',
  //    ],
  //    2
  //  )

  const returnGroupedBy = (list, keyGetter) => {
    const map = new Map()
    list.forEach((item) => {
      const key = keyGetter(item)
      const collection = map.get(key)
      if (!collection) {
        map.set(key, [item])
      } else {
        collection.push(item)
      }
    })
    return map
  }

  let groupedByUnit = returnGroupedBy(
    record.physicalOccurrences.sort(
      returnCustomOrder(archivedCopySortOrder, 'copyStatus')
    ),
    (copy) => copy?.referenceUnits?.[0]?.name
  )

  // Pulls an integer list from ContainerId strings
  // Given:     "Boxes 1-12, 13 and 14, 44, 65"
  // Returns:   [1, 2, 3, ..., 12, 13, 14, 44, 65]
  const returnIntContainerIDs = (containerId) => {
    console.log(containerId, 'hell')

    if (!containerId) return null

    return containerId
  }

  // Filters mediaOccurrences array to generalMediaTypes array of unique values:
  // 1. mediaOccurrences -> array of all generalMediaTypes
  // 2. Each element has one type of media as well as int containerIDs
  //
  // The Goal:
  // [
  //  {
  //    type: "Media Type"
  //    containerIDs: "1-10, 13, 17, 20-30"
  //  },
  //  ...
  // ]
  const returnUnique = (mediaOccurences) => {
    // getRanges sourced from StackOverflow user Christian C. Salvadó
    // https://stackoverflow.com/questions/2270910/how-to-reduce-consecutive-integers-in-an-array-to-hyphenated-range-expressions
    // [1, 2, 3, 4, 5, 10, 11, 12] =>
    // "1-5, 10-12"
    const getRanges = (arr) => {
      var ranges = [],
        start,
        end
      for (var i = 0; i < arr.length; i++) {
        start = arr[i]
        end = start
        while (arr[i + 1] - arr[i] == 1) {
          end = arr[i + 1] // increment the index if the numbers sequential
          i++
        }
        ranges.push(start == end ? start + '' : start + '-' + end)
      }
      return ranges.join(', ')
    }

    // Used as array.filter(filterUnique)
    function filterUnique(value, index, self) {
      return self.indexOf(value) === index
    }

    if (!mediaOccurences) return false

    var output = []
    // Separate mediaOccurrences into objects that look like:
    // {
    //  type: "Media Type"
    //  containerIDs: [1, 2, 3, ...]
    // }
    for (var j = 0; j < mediaOccurences.length; j++) {
      const type =
        mediaOccurences[j].specificMediaType ||
        mediaOccurences[j].generalMediaTypes[0]
      output.push({
        type: type,
        containerIDs: returnIntContainerIDs(mediaOccurences[j].containerId),
      })
    }
    // Consolidate mediaOccurrences by their type.
    // If two occurrences have the same type, their containerIDs are concatenated.
    output = output.reduce((acc, obj) => {
      let found = false
      for (let i = 0; i < acc.length; i++) {
        if (
          (Array.isArray(acc[i]?.type) &&
            Array.isArray(obj?.type) &&
            acc[i]?.type?.[0].toLowerCase() === obj?.type?.[0].toLowerCase()) ||
          (typeof acc[i]?.type === 'string' &&
            typeof obj?.type === 'string' &&
            acc[i].type?.toLowerCase() === obj.type?.toLowerCase())
        ) {
          found = true
          acc[i].containerIDs
            ? acc[i].containerIDs.concat(obj.containerIDs)
            : (acc[i].containerIDs = obj.containerIDs)
        }
      }
      if (!found) {
        acc.push(obj)
      }
      return acc
    }, [])
    // For each mediaOccurrence, sort the containerIDs, filter out repeat and NaN values
    for (let i = 0; i < output.length; i++) {
      if (Array.isArray(output[i].containerIDs)) {
        output[i].containerIDs = getRanges(
          output[i].containerIDs
            .sort(function (a, b) {
              return a - b
            })
            .filter(filterUnique)
            .filter(Boolean)
        )
      }
    }
    return output
  }

  const returnFormattedPhone = (fax) => {
    if (!fax) return false
    const regexObj = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    if (regexObj.test(fax)) {
      return fax.replace(regexObj, '($1) $2-$3')
    } else {
      return false
    }
  }

  const [data, setData] = useState([])
  const [displayExtent, setDisplayExtent] = useState(false)
  const [displayFootage, setDisplayFootage] = useState(false)
  const [displayRunTime, setDisplayRunTime] = useState(false)
  const [displayContainerIds, setDisplayContainerIds] = useState(false)
  const [hideColumns, setHideColumns] = useState([])

  const returnContainerIdsFromMediaOccurences = (value) => {
    let ids = []
    if (!value.mediaOccurrences) return false
    value.mediaOccurrences?.map((occurence) => {
      if (occurence.containerId)
        ids.push({
          id: occurence.containerId,
          type: [occurence.specificMediaType] || occurence.generalMediaTypes,
        })
    })
    return ids
  }

  const handleData = () => {
    let flattenedData = []
    for (let values of groupedByUnit.values()) {
      values.map((value, index) => {
        let containerIds = returnContainerIdsFromMediaOccurences(value)
        flattenedData.push({
          copy: { copy: index + 1 },
          purpose: { copy: index + 1, status: value.copyStatus },
          mediaTypes: {
            copy: index + 1,
            types: returnUnique(value.mediaOccurrences),
          },
          extent: <Highlighter search={q}>{value.extent}</Highlighter>,
          totalFootage: {
            copy: index + 1,
            length:
              value.totalFootage &&
              `${returnNumberWithCommas(value.totalFootage)} feet`,
          },
          totalRunningTime: {
            copy: index + 1,
            runtime: value.totalRunningTime,
            hours: returnHMSFromSeconds(value.totalRunningTime).hours,
            minutes: returnHMSFromSeconds(value.totalRunningTime).minutes,
            seconds: returnHMSFromSeconds(value.totalRunningTime).seconds,
          },
          containerIds: {
            copy: index + 1,
            containerIds: containerIds,
          },
          details: { copy: index + 1, data: value },
        })
        if (value.extent) setDisplayExtent(true)
        if (value.totalFootage) setDisplayFootage(true)
        if (value.totalRunningTime) setDisplayRunTime(true)
        // if (containerIds.length > 0) setDisplayContainerIds(true)
      })
    }
    setData(flattenedData)
  }

  useEffect(() => {
    handleData()
  }, [record])

  useEffect(() => {
    const columns = [
      ...(!displayExtent ? ['extent'] : []),
      ...(!displayFootage ? ['totalFootage'] : []),
      ...(!displayRunTime ? ['totalRunningTime'] : []),
      ...(!displayContainerIds ? ['containerIds'] : []),
    ]
    setHideColumns(columns)
  }, [displayExtent, displayFootage, displayRunTime])

  const columns = useMemo(
    () => [
      {
        Header: 'Copy',
        accessor: 'copy',
        className: 'text-center width-6',
        id: 'copy',
        Cell: (cell) => {
          return (
            <p>
              <span className="usa-sr-only">Copy: </span>
              {cell.value.copy}
              <span className="usa-sr-only">, </span>
            </p>
          )
        },
      },
      {
        Header: (
          <HeaderToolTip
            title="Copy Status"
            description={toolTipDescriptions.purpose}
          />
        ),
        accessor: 'purpose',
        id: 'use',
        Cell: (cell) => {
          return (
            <p>
              <span className="usa-sr-only">
                Purpose for Copy {cell.value.copy}:{' '}
              </span>
              {cell.value.status
                .split('-')
                .sort(returnCustomOrder(archivedCopySortOrder, 'index'))
                .map((copy, index) => (
                  <span className="display-block" key={copy}>
                    {copy}
                    {index !== cell.value.status.split('-').length - 1
                      ? index === cell.value.status.split('-').length - 2
                        ? ', and '
                        : ', '
                      : ''}
                  </span>
                ))}
              <span className="usa-sr-only">, </span>
            </p>
          )
        },
      },
      {
        Header: (
          <HeaderToolTip
            title="Specific Media Type"
            description={toolTipDescriptions.mediaTypes}
          />
        ),
        accessor: 'mediaTypes',
        id: 'media',
        Cell: (cell) => {
          return Array.isArray(cell?.value?.types) ? (
            <>
              <span className="usa-sr-only">
                Media{' '}
                {cell.value.types.length > 1 &&
                cell.value.types.filter((t) => t.count > 1).length > 1
                  ? 'Types'
                  : 'Type'}{' '}
                for Copy {cell.value.copy}:{' '}
              </span>{' '}
              <ol
                className={[
                  'add-list-reset',
                  'display-flex',
                  'flex-column',
                ].join(' ')}
              >
                {cell.value.types.map((type) => (
                  <li key={type.type}>
                    {type.type}
                    {type.containerIDs &&
                      (type.containerIDs.includes(',') ||
                      type.containerIDs.includes('-')
                        ? ' (Container ID: ' + type.containerIDs + ')'
                        : ' (Container ID: ' + type.containerIDs + ')')}
                  </li>
                ))}
              </ol>
              <span className="usa-sr-only">, </span>
            </>
          ) : (
            ''
          )
        },
      },
      {
        Header: (
          <HeaderToolTip
            title="Container IDs"
            description={toolTipDescriptions.containerIds}
          />
        ),
        accessor: 'containerIds',
        id: 'containerIds',
        Cell: (cell) => {
          return Array.isArray(cell?.value?.containerIds) ? (
            <>
              <span className="usa-sr-only">
                {cell.value.containerIds.length > 1
                  ? 'Container IDs'
                  : 'Container ID'}{' '}
                for Copy {cell.value.copy}:{' '}
              </span>
              <ol
                className={[
                  'add-list-reset',
                  'display-flex',
                  'flex-column',
                ].join(' ')}
              >
                {cell.value.containerIds.map((id, index) => (
                  <li key={id.id + index}>
                    {id.type?.join(', ')}:{' '}
                    <Highlighter search={q}>{id.id}</Highlighter>
                  </li>
                ))}
              </ol>
              <span className="usa-sr-only">, </span>
            </>
          ) : (
            ''
          )
        },
      },
      {
        Header: (
          <HeaderToolTip
            title="Extent"
            description={toolTipDescriptions.extent}
          />
        ),
        accessor: 'extent',
        id: 'extent',
      },
      {
        Header: (
          <HeaderToolTip
            title="Total Footage"
            description={toolTipDescriptions.totalFootageLength}
          />
        ),
        accessor: 'totalFootage',
        id: 'totalFootage',
        Cell: (cell) => {
          return cell.value.length ? (
            <p>
              <span className="usa-sr-only">
                Total Footage Length for Copy {cell.value.copy}:{' '}
              </span>
              {cell.value.length}
            </p>
          ) : (
            <span className="usa-sr-only">
              No Total Footage Length for Copy {cell.value.copy},
            </span>
          )
        },
      },
      // The total duration in minutes of an audiovisual item.
      {
        Header: (
          <HeaderToolTip
            title="Total Running Time"
            description={toolTipDescriptions.totalRunningTime}
          />
        ),
        accessor: 'totalRunningTime',
        id: 'totalRunningTime',
        Cell: (cell) => {
          return cell.value.runtime ? (
            <p>
              <span className="usa-sr-only">
                Total Running Time for Copy {cell.value.copy}:{' '}
              </span>

              {cell.value.hours > 0 && (
                <Fragment>
                  {cell.value.hours > 1
                    ? `${cell.value.hours} hours`
                    : '1 hour'}
                  {cell.value.hours > 0 && cell.value.minutes > 0
                    ? cell.value.seconds > 0
                      ? ', '
                      : ' and '
                    : ''}{' '}
                </Fragment>
              )}
              {cell.value.minutes > 0 && (
                <Fragment>
                  {cell.value.minutes > 1
                    ? `${cell.value.minutes} minutes`
                    : '1 minute'}
                  {(cell.value.hours > 0 || cell.value.minutes > 0) &&
                  cell.value.minutes > 0
                    ? ' and '
                    : ''}{' '}
                </Fragment>
              )}
              {cell.value.seconds > 1
                ? `${cell.value.seconds} seconds`
                : '1 second'}
            </p>
          ) : (
            ''
          )
        },
      },
      {
        Header: (
          <HeaderToolTip
            title="Details"
            description={toolTipDescriptions.details}
          />
        ),
        className: 'width-12',
        accessor: 'details',
        id: 'details',
        Cell: (cell) => {
          return <DetailsModal copy={cell.value.copy} data={cell.value.data} />
        },
      },
    ],
    [hideColumns]
  )

  const handleSetPage = (num) => {}

  let localIdsString = ''
  if (record.localIdentifier) {
    let ids = record.localIdentifier.split(';')
    localIdsString =
      ids.length > 1 ? 'Local Identifiers:' : `Local Identifier:%02${ids?.[0]}`
    if (ids.length > 1)
      ids.map((id) => {
        localIdsString += `%0D%0A%02%02%02%02%02-%02${id.trim()}`
      })
  }
  return (
    <div className="width-full">
      <SectionHeading
        copyLink
        count={record.physicalOccurrences.length}
        {...props}
      >
        {record.physicalOccurrences.length > 1
          ? 'Archived Copies'
          : 'Archived Copy'}
      </SectionHeading>

      <Alert type="warning">
        <p className="margin-bottom-1">
          The following may not represent the current availability of these
          records.
        </p>
        <p>
          If you plan to access these records in person, please contact the
          following {groupedByUnit.length > 1 ? 'locations' : 'location'} prior
          to your visit to confirm current availability.
        </p>
      </Alert>

      {Array.from(groupedByUnit.entries()).map((key, index, map) => {
        let address = map
        let SectionData = data
        if (address?.length > 1 && data[index]) {
          SectionData = [data[index]]
        } else {
          SectionData = data
        }
        return (
          key?.[0] && (
            <Fragment key={key?.[0]}>
              <div className="tablet:padding-x-3 margin-x-neg-2 tablet:margin-x-neg-0">
                <div className="overflow-x-scroll padding-bottom-2 tablet:padding-bottom-0">
                  <Card
                    className="padding-0 tablet-lg:padding-0 margin-x-2 tablet:margin-x-0"
                    style={{ minWidth: '36rem' }}
                  >
                    <Table
                      caption={
                        <div
                          className={[
                            'display-flex',
                            'flex-column',
                            'flex-gap-sm',
                          ].join(' ')}
                          data-testid="nac-description__section-archived-copies--contact"
                        >
                          <div>
                            <span className="usa-sr-only">
                              Archived Copy Location:
                            </span>
                            <div
                              className={[
                                'flex-row',
                                'grid-row',
                                'flex-justify',
                              ].join(' ')}
                            >
                              <strong className="margin-top-1">
                                <Highlighter search={q}>{key?.[0]}</Highlighter>
                              </strong>
                              {data.length > 1 && (
                                <AllCopiesModal data={data} />
                              )}
                            </div>
                            <span className="usa-sr-only">, </span>
                          </div>
                          <address>
                            <ListSeparator>
                              <div className="flex-row grid-row margin-top-1">
                                <div className="flex-column grid-col-auto margin-right-1">
                                  <Icon
                                    iconName="location"
                                    iconSize="xs"
                                    color="base-darker"
                                  />
                                </div>
                                <span className="usa-sr-only">Address: </span>
                                <div className="flex-column grid-col">
                                  <div className="margin-bottom-05">
                                    {key?.[1]?.[0]?.referenceUnits?.[0] && (
                                      <>
                                        {key?.[1]?.[0]?.referenceUnits?.[0]
                                          ?.address2 ? (
                                          <>
                                            <Highlighter search={q}>
                                              {
                                                key?.[1]?.[0]
                                                  ?.referenceUnits?.[0]
                                                  ?.address2
                                              }
                                            </Highlighter>
                                            ,{' '}
                                          </>
                                        ) : (
                                          ''
                                        )}
                                        {key[1][0]?.referenceUnits[0]?.city ? (
                                          <>
                                            <Highlighter search={q}>
                                              {
                                                key[1][0]?.referenceUnits[0]
                                                  ?.city
                                              }
                                            </Highlighter>
                                            ,{' '}
                                          </>
                                        ) : (
                                          ''
                                        )}
                                        {key[1][0]?.referenceUnits[0]?.state ? (
                                          <>
                                            <Highlighter search={q}>
                                              {
                                                key[1][0]?.referenceUnits[0]
                                                  ?.state
                                              }
                                            </Highlighter>
                                            ,{' '}
                                          </>
                                        ) : (
                                          ''
                                        )}
                                        {key[1][0]?.referenceUnits[0]
                                          ?.postalCode ? (
                                          <Highlighter search={q}>
                                            {
                                              key[1][0]?.referenceUnits[0]
                                                ?.postalCode
                                            }
                                          </Highlighter>
                                        ) : (
                                          ''
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <span className="usa-sr-only">, </span>
                                </div>
                              </div>
                              {key[1][0]?.referenceUnits[0]?.phone && (
                                <div className="flex-row grid-row margin-top-1">
                                  <div className="flex-column grid-col-auto margin-right-1">
                                    <Icon
                                      iconName="phone"
                                      iconSize="xs"
                                      color="base-darker"
                                    />
                                  </div>
                                  <div className="flex-column grid-col">
                                    <a
                                      className="margin-bottom-05"
                                      href={`tel:1 ${returnFormattedPhone(
                                        key[1][0]?.referenceUnits[0]?.phone
                                      )}`}
                                    >
                                      <span className="usa-sr-only">
                                        Phone Number:{' '}
                                      </span>
                                      {returnFormattedPhone(
                                        key[1][0]?.referenceUnits[0]?.phone
                                      )}
                                    </a>
                                  </div>
                                  <span className="usa-sr-only">, </span>
                                </div>
                              )}
                              {key[1][0]?.referenceUnits[0]?.fax && (
                                <div className="flex-row grid-row margin-top-1">
                                  <div className="flex-column grid-col-auto margin-right-1">
                                    <Icon
                                      iconName="fax"
                                      iconSize="xs"
                                      color="base-darker"
                                    />
                                  </div>
                                  <div className="flex-column grid-col">
                                    <a
                                      className="margin-bottom-05"
                                      href={`tel:1 ${returnFormattedPhone(
                                        key[1][0]?.referenceUnits[0]?.fax
                                      )}`}
                                    >
                                      <span className="usa-sr-only">
                                        Fax Number:{' '}
                                      </span>
                                      {returnFormattedPhone(
                                        key[1][0]?.referenceUnits[0]?.fax
                                      )}
                                    </a>
                                  </div>
                                  <span className="usa-sr-only">, </span>
                                </div>
                              )}
                              {key[1][0]?.referenceUnits[0]?.email && (
                                <div className="flex-row grid-row margin-top-1">
                                  <div className="flex-column grid-col-auto margin-right-1">
                                    <Icon
                                      iconName="at"
                                      iconSize="xs"
                                      color="base-darker"
                                    />
                                  </div>
                                  <div className="flex-column grid-col">
                                    <a
                                      href={`mailto: ${
                                        key[1][0]?.referenceUnits[0]?.email
                                      }?subject=RE: ${
                                        record.physicalOccurrences.length > 1
                                          ? 'Archived Copies'
                                          : 'Archived Copy'
                                      } for NAID ${
                                        record.naId
                                      }&body=Link to Record Page: ${
                                        window.location.hostname
                                      }${
                                        window.location.pathname
                                      }#archived-copies%0D%0ARecord Title: ${
                                        record.title
                                      }%0D%0ANational Archives Identifier: ${
                                        record.naId
                                      }%0D%0A${localIdsString}`}
                                      className="margin-bottom-05"
                                    >
                                      <span className="usa-sr-only">
                                        Email:{' '}
                                      </span>
                                      {key[1][0]?.referenceUnits[0]?.email}
                                    </a>
                                  </div>
                                  <span className="usa-sr-only">, </span>
                                </div>
                              )}
                            </ListSeparator>
                          </address>
                        </div>
                      }
                      hideColumns={hideColumns.length > 0 ? hideColumns : null}
                      columns={columns}
                      data={SectionData}
                      pageNum={1}
                      setPage={handleSetPage}
                      //loading={loadingRelated}
                      //pageCount={pages}
                    />
                    {key[1]?.filter((k) => k.containerList)?.[0] && (
                      <>
                        <hr className={['border-base-lightest'].join(' ')} />
                        <div
                          className={[
                            'font-sans-xs',
                            'padding-2',
                            'text-base-darker',
                          ].join(' ')}
                        >
                          <h3
                            className={[
                              'font-sans-2xs',
                              'text-base-darker',
                              'text-bold',
                            ].join(' ')}
                          >
                            Container List
                          </h3>
                          <ol
                            className={[
                              'add-list-reset',
                              'display-flex',
                              'flex-column',
                            ].join(' ')}
                          >
                            <ExpandCollapseList gap="sm">
                              {key[1]
                                .filter((k) => k.containerList)[0]
                                .containerList.split(/\r?\n/)
                                .map((list) => (
                                  <li key={list}>
                                    <Prose>{list}</Prose>
                                  </li>
                                ))}
                            </ExpandCollapseList>
                          </ol>
                        </div>
                      </>
                    )}
                  </Card>
                </div>
              </div>
            </Fragment>
          )
        )
      })}
    </div>
  )
}
export default SectionArchivedCopies

const HeaderToolTip = ({ title, description, ...props }) => {
  return (
    <div className="display-flex flex-gap-sm flex-row">
      {title}
      <Tooltip
        content={description}
        delay={500}
        display="block"
        // ex. #total-footage-length
        id={'#' + title.toLowerCase().split(' ').join('-')}
        position="topCenter"
      >
        <Button
          className={['padding-0'].join(' ')}
          iconName="circle-questions"
          iconOnly
          iconSize="xs"
          reduced
          shape="pill"
          srText="More details"
          textOnly
          theme="base-dark"
        />
      </Tooltip>
    </div>
  )
}

HeaderToolTip.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

const toolTipDescriptions = {
  allCopies: 'Access the full details for all copies.',
  containerIds:
    'The identifier or number for the individual container storing each specific media type.',
  details: 'Access the full details for this copy.',
  extent:
    'The measurement or amount of the archival materials themselves -- the linear measurement, page count, number of rolls, reels, cassettes, or number of data files.',
  mediaTypes: 'The physical form of the archival materials.',
  purpose:
    'The role or purpose of each physical occurrence (copy) of the archival materials. The purposes are preservation, reference, reproduction or some combination of the three.',
  totalFootageLength:
    'The length in feet of all the reels or rolls that make up the entire copy of an audiovisual item.',
  totalRunningTime: 'The total duration of an audiovisual item.',
}
