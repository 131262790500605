import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
//Context
import SearchContext from '../../contexts/SearchContext'

const useSearchInput = (onSubmit, isSearchWithin) => {
  const { pathname } = useLocation()
  const {
    activeQueryParams,
    handleActiveQueryParams,
    handleSearchChange,
    searchQuery,
    submittedQuery,
  } = useContext(SearchContext) || {}

  const { search } = useLocation()
  const query = queryString.parse(search)

  const [value, setValue] = useState(
    sessionStorage.getItem('search') || query.q || activeQueryParams.q || ''
  )

  const [searchWithinValue, setSearchWithinValue] = useState('')

  useEffect(() => {
    if (!isSearchWithin && !query.q) {
      sessionStorage.removeItem('setSearch')
    } else if (isSearchWithin && query.q != null && query.q !== '') {
      sessionStorage.setItem('setSearch', query.q)
    }
    if (!isSearchWithin) {
      activeQueryParams.q = ''
    }
  }, [!isSearchWithin, query.q, activeQueryParams.q])

  const handleOnChange = (event) => {
    isSearchWithin
      ? setSearchWithinValue(event.target.value)
      : setValue(event.target.value)
    handleSearchChange(event)
    if (pathname.indexOf('/search') > -1 || pathname == '/') {
      let newParams = Object.assign({}, activeQueryParams)
      newParams.q = event.target.value
      handleActiveQueryParams(newParams)
    }
  }

  const handleOnSubmit = (event) => {
    const passValue = isSearchWithin
      ? searchWithinValue
      : value
      ? value
      : searchQuery
      ? searchQuery
      : submittedQuery
      ? submittedQuery
      : ''

    onSubmit(event, passValue)
  }

  useEffect(() => {
    if (pathname && pathname === '/') {
      setTimeout(() => {
        setValue(sessionStorage.getItem('search') || '')
      }, 0)
    }
  }, [pathname])

  useEffect(() => {
    if (!pathname.includes('/search-within')) {
      if (query.q) {
        setValue(query.q)
      } else if (activeQueryParams.q) {
        setValue(sessionStorage.getItem('search') || activeQueryParams.q)
      } else {
        setValue(sessionStorage.getItem('search') || '')
      }
    }
  }, [search])

  return {
    handleOnChange,
    handleOnSubmit,
    searchQuery,
    value,
    searchWithinValue,
  }
}
export default useSearchInput
