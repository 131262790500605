import DOMPurify from 'dompurify'
import { useContext, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
//Context
import AccountContext from '../../../contexts/AccountContext'
import {
  audioTypes,
  imageTypes,
  videoTypes,
} from '../../../helper-functions/metadata-functions'
import { convertCamelToSentence } from '../../../helper-functions/string-functions'
import useHttp from '../../../hooks/use-http'
import useInput from '../../../hooks/use-input'
import {
  removeExtraWhiteSpace,
  validateInputFromSchema,
} from '../../form/Validate/Validate'
import {
  DEFAULT_CONTRIBUTION_FEED_LIMIT,
  DEFAULT_CONTRIBUTION_VIEW,
  DEFAULT_FEED_PAGE,
  SEARCH_URL,
} from '../../settings/globals'

const useActivityFeed = () => {
  /**
   * Context
   */
  const { account, contributionTotals, uniqueTags } =
    useContext(AccountContext) || {}
  const { userId } = account || {}

  /**
   * Hooks
   */
  const { feedPage } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const sort = searchParams.get('sort')
  // Determines the display of the feed as either a feed list or table
  const view = searchParams.get('view') || DEFAULT_CONTRIBUTION_VIEW

  /**
   * State
   */
  // An array of the current displayed feed item data
  const [feed, setFeed] = useState([])
  // The indexed page to use for the activity feed query (i.e. limit)
  const [page, setPage] = useState(null)
  // An array of NAIDs which match the requested contribution targetNaIds
  // True when the initial contribution results are loading
  const [loadingFeed, setLoadingFeed] = useState(false)
  // True when the load more button is clicked and additional results are loading
  const [loadingMore, setLoadingMore] = useState(false)
  // True when the complimentary record data is loading
  const [loadingRecords, setLoadingRecords] = useState(false)
  // True when the initial search results are loading
  const [loadingSearched, setLoadingSearched] = useState(false)
  // Used for retrieving related record details
  const [records, setRecords] = useState([])
  // Indicates when to override the stored state with an entirely new state
  // used for searching and switching between pages
  const [resetData, setResetData] = useState(false)
  // The value submitted in the contribution activity search input
  const [submittedValue, setSubmittedValue] = useState(null)
  // Error thrown on search
  const [searchError, setSearchError] = useState(null)

  /**
   * Handle Search
   */
  const searchErrorHandler = (error) => {
    setSearchError(error)
  }

  const {
    isValid: enteredSearchIsValid,
    value: enteredSearch,
    inputBlurHandler: blurHandler,
    reset: resetInput,
    valueChangeHandler: changeHandler,
  } = useInput(
    (value) => validateInputFromSchema({ type: 'text' }, value),
    searchErrorHandler
  )

  const sanitizedInput = removeExtraWhiteSpace(
    DOMPurify.sanitize(enteredSearch)
  )

  const submitHandler = (event) => {
    event.preventDefault()
    if (userId && !loadingFeed) {
      setResetData(true)
      setLoadingSearched(true)
      setSubmittedValue(sanitizedInput)
      setPage(DEFAULT_FEED_PAGE)
    }
  }

  const viewClickHandler = (value = DEFAULT_CONTRIBUTION_VIEW) => {
    if (value === DEFAULT_CONTRIBUTION_VIEW) searchParams.delete('view')
    else searchParams.set('view', value)
    setSearchParams(searchParams)
    // Set this here to prevent flash of old content
    setFeed([])
    setRecords([])
    setPage(DEFAULT_FEED_PAGE)
    setLoadingFeed(true)
    setResetData(true)
    // setSubmittedValue(null)
  }

  /**
   * Handle Defaults
   * Each time a new page loads, or the search params update,
   * update the page state to kick off a new request
   */
  useEffect(() => {
    if (!userId) return false
    setFeed([])
    setPage(DEFAULT_FEED_PAGE)
    setLoadingFeed(true)
    setResetData(true)
    // setSubmittedValue(null)
  }, [userId, feedPage, searchParams])

  /**
   * Once the page updates, request a new set of contributions
   */
  useEffect(() => {
    if (!userId) return false
    // if (!page) setPage(DEFAULT_FEED_PAGE)
    if (page === 1 && (!feed || totalResults === 0)) {
      setResetData(true)
      setLoadingFeed(true)
    }
  }, [page])

  useEffect(() => {
    const abortController = new AbortController()
    if (
      contributionTotals?.totals?.totalContributions >= 0 &&
      (feedPage === 'tags'
        ? contributionTotals?.totals?.totalTags > 0 &&
          uniqueTags?.uniqueTags?.length > 0
        : true)
    ) {
      if (loadingFeed || loadingMore || loadingSearched) {
        requestContributionHandler(abortController)
      }
    }
    return () => {
      abortController.abort()
    }
  }, [
    loadingFeed,
    loadingMore,
    loadingSearched,
    contributionTotals,
    feedPage,
    uniqueTags,
  ])

  /**
   * Request Contributions
   * a request must be sent to the MySQL server to retreive contributions
   * a little at a time.
   */
  const handleContributionResponse = (results) => {
    let newFeed = []
    let newRecords = []
    results.map((result) => {
      newFeed.push(result)
      newRecords.push(result.targetNaId)
    })
    setRecords(newRecords)
    if (resetData || view === 'table') setFeed(newFeed)
    else setFeed([...feed, ...newFeed])

    // Sort by most/least contributions
    // newFeed: Array with contribution data
    // newRecords: Array with contriubtion targetNaId's
    if (
      sort &&
      (sort === 'leastContributions' || sort === 'mostContributions')
    ) {
      let temp = []
      // filter to unique naId's
      let uniqueRecords = newRecords.filter(function onlyUnique(
        value,
        index,
        self
      ) {
        return self.indexOf(value) === index
      })
      // GROUPED BY NAID
      // Temp becomes an array of length unique naIds, and each element is an array
      // containing all the contriubtions for that naId
      for (let i = 0; i < uniqueRecords.length; i++) {
        temp.push([])
        for (let j = 0; j < newFeed.length; j++) {
          if (newFeed[j].targetNaId === uniqueRecords[i]) {
            temp[i].push(newFeed[j])
          }
        }
      }
      // GROUPED BY MOST/LEAST CONTRIBUTIONS
      temp.sort(function (a, b) {
        if (sort === 'mostContributions') return b.length - a.length
        return a.length - b.length
      })
      // Flatten to one array
      temp = [].concat(...temp)
      setFeed(temp)
    }

    setTimeout(() => {
      setResetData(false)
      setLoadingFeed(false)
      setLoadingSearched(false)
      setLoadingMore(false)
    }, 500)
  }

  const { sendRequest: requestContributionHandler } = useHttp(
    {
      url: `${SEARCH_URL}/contributions/userId/${userId}?${
        sort ? `sort=updatedAt:${sort}&` : ''
      }${
        feedPage ? `contributionType=${feedPage.slice(0, -1)}&` : ''
      }status=active&page=${
        page || DEFAULT_FEED_PAGE
      }&limit=${DEFAULT_CONTRIBUTION_FEED_LIMIT}${
        sanitizedInput ? `&q=${sanitizedInput}` : ''
      }`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    handleContributionResponse
  )

  const loadMoreHandler = () => {
    if (!loadingFeed && !loadingRecords) {
      setLoadingMore(true)
      setPage(page + 1)
    }
  }

  const setFeedPageHandler = (newPage) => {
    if (!loadingFeed && !loadingRecords) {
      setLoadingMore(true)
      setPage(newPage)
    }
  }

  /**
   * Request Records
   * A separate request must be made to opensearch to return all matching
   * record titles and digital object numbers for the display
   */

  const recordsResponseHandler = (results) => {
    setLoadingRecords(false)
    const resultsArray = results.body.hits.hits
    let flattenedRecords = feed.map((x) => {
      return { ...x }
    }) // Prevent mutation of original state
    flattenedRecords.map((item) => {
      resultsArray.map((result) => {
        let record = { ...result._source.record, ...result.fields }
        if (record?.naId == item.targetNaId) {
          item.recordTitle = record?.title || record?.heading
          item.recordType = record?.generalRecordsTypes?.[0]
          item.level = record?.levelOfDescription || record?.authorityType
        }
        if (record?.digitalObjects && item.targetObjectId)
          record?.digitalObjects.map((object, index) => {
            if (item.targetObjectId == object?.objectId) {
              item.objectUrl = object?.objectUrl
              item.objectNumber =
                record?.digitalObjects.length == 1
                  ? 1
                  : object?.pageNum
                  ? object?.pageNum
                  : index + 1
              item.objectsTotal = record?.digitalObjects?.length
              item.objectType = returnActiveObjectType(object?.objectType)
            }
          })
      })
      return item
    })
    setFeed(flattenedRecords)
  }

  // If any record NAIDs were identified, request the records from Opensearch
  useEffect(() => {
    if (
      !loadingFeed &&
      !loadingMore &&
      !loadingSearched &&
      records?.length > 0
    ) {
      const abortController = new AbortController()
      recordsRequestHandler(abortController)
    }
  }, [records, loadingFeed, loadingMore, loadingSearched])

  const { sendRequest: recordsRequestHandler } = useHttp(
    {
      url: `${SEARCH_URL}/records/search?naId=${records}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    recordsResponseHandler
  )

  const totalResults =
    feed?.[0]?.total ||
    contributionTotals?.totals?.[
      `total${feedPage ? convertCamelToSentence(feedPage) : 'Contributions'}`
    ]
  const userIsSearching = (sanitizedInput || submittedValue) && page === 1
  const totalDisplayed = feed?.length
  const displayLoadMore =
    totalDisplayed > 0 &&
    totalResults > DEFAULT_CONTRIBUTION_FEED_LIMIT * (page || 1)

  return {
    changeHandler,
    displayLoadMore,
    enteredSearch,
    feed,
    page,
    loadingFeed,
    loadingMore,
    loadMoreHandler,
    loadingRecords,
    loadingSearched,
    setFeedPageHandler,
    submitHandler,
    submittedValue,
    totalDisplayed,
    totalResults,
    userIsSearching,
    view,
    viewClickHandler,
  }
}

export default useActivityFeed

export const returnActiveObjectType = (objectType) => {
  if (!objectType) return 'Object'
  else if (imageTypes.some((element) => objectType.includes(element)))
    return 'Image'
  else if (videoTypes.some((element) => objectType.includes(element)))
    return 'Video'
  else if (audioTypes.some((element) => objectType.includes(element)))
    return 'Audio File'
  else return 'Object'
}
